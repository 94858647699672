import React, { useState } from 'react';
import {
  SettingsFormGroup,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormLabelRightContainer,
  OpeningHoursContainer,
  AddNewFieldButton,
  SalesTaxCard,
  SettingsFormInputContainer,
  SettingsSwitchContainer,
  SettingsActionButtonWrapper,
  SettingsActionButtonContainer,
} from '..';
import { IconEligibleItemCheck, IconHelp, IconHelpV2, IconPlus } from '../../../../../components/v2/icons';
import { RotateLoader, SelectAdv, Switch, styled } from '@lib/components';
import { IconList, IconPicker, IconPickerItem } from 'react-fa-icon-picker';
import {
  CookieColorsContainer,
  CookieColorsRow,
  CookieColorPickerWrapper,
  CookieColorPickerContent,
} from '../policies-agreements';
import { FaTrash } from 'react-icons/fa';

import { RestaurantForm } from '../../../../../../mobx/components/restaurant-form';
import { useStores } from '../../../../../../mobx/useStores';
import { updateRestaurantNotificationOptions } from '../../../../../../core/libs/receipt';
import { UI } from '../../../../../../core/ui';
import { FastField } from 'formik';
import shortid from 'shortid';
import { SettingsSuccessIndicator } from '../../settings/components';
import { SettingsInactiveIndicator } from '../../settings/components';

const CustomFieldButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 16px;
`;
const CustomFieldButton = styled.div`
  display: flex;
  padding: 8px;
  background: #f5f5f5;
  color: #212121;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  outline: 0;
  border: 1px solid #e0e0e0;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
`;

interface FormValues {
  pickup: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["pickup"],
  delivery: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"],
  table_booking: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["table_booking"],
  dine_in: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["dine_in"]
}
const createDefaultCustomCheckoutObject = (increment: number) => {
  return {
    _id: shortid.generate(),
    enabled: false,
    type: "checkbox",
    icon: "FaHandPaper",
    label: `Custom Field #${increment + 1}`,
    required: false,
    options: [],

  } as T.Schema.Restaurant.Services.CustomCheckoutField
}
export const CustomCheckoutFields = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [fields, setFields] = useState<Array<any>>([
    createDefaultCustomCheckoutObject(0)
  ]);
  const [editCustomModal, setEditCustomModal] = useState(false);
  const handleAddNewField = () => {
    if (fields.length < 2) {
      setFields([...fields, {}]);
    }
  };
  const handleEditCustomFieldClick = (flag: boolean) => {
    store.setActiveMobileModal(flag ? 'edit-custom-checkout-field': '');
  };
  const handleRemoveField = (index: number) => {
    setFields(fields.filter((_, i) => i !== index));
  };
  const store = useStores();
  const r = store.restaurant!;
  const { injected, referrer } = props

  let service: T.Schema.Restaurant.Services.RestaurantServiceTypes = 'pickup';
  if (referrer.includes('deliveries')) {
    service = 'delivery';
  } else if (referrer.includes('dine-ins')) {
    service = 'dine_in';
  } else if (referrer.includes('table-booking')) {
    service = 'table_booking';
  }
  let initialValues = r.settings.services[service];
  const [selectedOption, setSelectedOption] = useState<T.Schema.Restaurant.Services.CustomCheckoutField | null>(null)


  return (
    <div style={{ paddingBottom: 83 }}>

      <RestaurantForm<FormValues>
        submit={async (r, values) => {
          {/*@ts-ignore*/ }
          r.settings.services[service] = values;
          const update = { $set: { [`settings.services.${service}`]: r.settings.services[service] } };
          return { r, update };
        }}
        //@ts-ignore
        initialValues={initialValues}
        onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
        onError={() => UI.notification.error("An error occurred")}
        onSuccessMessage="Settings Updated"
        width='100%'
      >
        {({ form, error, getFieldError }) => {
          const { isSubmitting, setFieldValue } = form;
          return (
            <>
              {store.activeMobileModal === '' && (
                <SettingsFormGroup>
                  {
                    //@ts-ignore
                    (form.values.custom_checkout_fields || []).map(
                      (field: T.Schema.Restaurant.Services.CustomCheckoutField, index: number) => (
                        <CustomField
                          index={index}
                          key={index}
                          field={field}
                          onClick={() => {
                            store.setActiveMobileMenu('edit-custom-checkout-field', 'custom-checkout-field')
                            store.setActiveMobileModal(service+'|edit-custom-checkout-field')
                            setSelectedOption(field)
                          }}
                          onRemove={() => {
                            //@ts-ignore
                            const copyValues = form.values.custom_checkout_fields
                            copyValues.splice(index, 1)
                            setFieldValue(
                              'custom_checkout_fields',
                              copyValues
                            )
                          }} />
                      ))}
                  <SettingsFormGroupInput>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      {
                        //@ts-ignore
                        (form.values.custom_checkout_fields || [] as T.Schema.Restaurant.Services.CustomCheckoutField[]).length < 2 && (
                          <AddNewFieldButton type='button' onClick={() => {
                            //@ts-ignore
                            if (!form.values.custom_checkout_fields) {
                              setFieldValue(
                                'custom_checkout_fields',
                                ([] as T.Schema.Restaurant.Services.CustomCheckoutField[]).concat(createDefaultCustomCheckoutObject(0)))
                            } else {
                              setFieldValue(
                                'custom_checkout_fields',
                                //@ts-ignore
                                form.values.custom_checkout_fields.concat(createDefaultCustomCheckoutObject(form.values.custom_checkout_fields.length)))
                            }
                          }}>Add New Field</AddNewFieldButton>
                        )}
                    </div>
                    <OpeningHoursContainer>
                      <p>
                        Set custom checkout fields for this service. This allows us to get more information from your customer.
                        Each service will have a maximum of 2 custom checkout fields.
                      </p>
                    </OpeningHoursContainer>
                  </SettingsFormGroupInput>
                </SettingsFormGroup>
              )}
              {store.activeMobileModal.includes('edit-custom-checkout-field') && <EditCustomField
                form={form}
                handleEditCustomFieldClick={handleEditCustomFieldClick}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                handleUpdateOption={(option: T.Schema.Restaurant.Services.CustomCheckoutField) => {
                  //@ts-ignore
                  const copyValues = form.values.custom_checkout_fields
                  const findOptionIndex = copyValues.findIndex((c: T.Schema.Restaurant.Services.CustomCheckoutField) => c._id == option._id)
                  copyValues[findOptionIndex] = { ...option }
                  form.setFieldValue(
                    'custom_checkout_fields',
                    copyValues
                  )
                }}
              />}
              <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                  <button type="button"
                    className="default btn-cancel"
                    onClick={() => store.setActiveMobileMenu(
                      store.activeMobileMenu.referrer.pop()!,
                      Array.from(new Set(store.activeMobileMenu.referrer))
                    )}>
                    Cancel
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && "Save"}
                  </button>
                </SettingsActionButtonWrapper>
              </SettingsActionButtonContainer>
            </>
          )
        }}
      </RestaurantForm>
    </div>
  );
};

export const CustomField = ({ index, onClick, onRemove, field }:
  {
    index: number,
    onClick: () => void,
    onRemove: () => void,
    field: T.Schema.Restaurant.Services.CustomCheckoutField,
  }) => {
  //const [defaultOptions, setOptions] = useState(options || ['Option 1', 'Option 2'])
  const [selectedOption, setSelectedOption] = useState('')
  const { type, label, icon, enabled, dropdown_options } = field
  return (
    <>
      <SalesTaxCard style={{ width: '100%' }}>
        <SettingsFormGroup>
          <SettingsFormGroupInput>
            <SettingsFormLabelContainer>
              <SettingsFormLabelLeftContainer onClick={onClick} style={{ cursor: 'pointer' }}>
                {icon && <IconPickerItem icon={icon as IconList} size={20} />}
                {label}
              </SettingsFormLabelLeftContainer>
              {type == 'checkbox' && (<SettingsFormLabelRightContainer>
                <input type='checkbox' />
              </SettingsFormLabelRightContainer>)}
            </SettingsFormLabelContainer>
            {(type == 'input' || type == 'dropdown') && (<SettingsFormInputContainer>
              {type == 'input' && (<input type='text' />)}
              {type == 'dropdown' && dropdown_options && (
                <SelectAdv
                  options={dropdown_options.map(d => { return { label: d, value: d } })}
                  type='single'
                  value={selectedOption}
                  onChange={(option) => setSelectedOption(option)}
                />)}
            </SettingsFormInputContainer>)}
          </SettingsFormGroupInput>
        </SettingsFormGroup>
      </SalesTaxCard>
      <CustomFieldButtonWrapper>

        <CustomFieldButton onClick={onClick}>
          {enabled ? (
            <SettingsSuccessIndicator
              background={'#51a351'}
            />
          ) : (
            <SettingsInactiveIndicator />
          )}
          {enabled ? "Enabled" : "Disabled"}
        </CustomFieldButton>
        <CustomFieldButton
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}><FaTrash /> Remove</CustomFieldButton>
      </CustomFieldButtonWrapper>
    </>
  );
};

export const EditCustomField = (props: any) => {
  const { form, handleEditCustomFieldClick, selectedOption, setSelectedOption, handleUpdateOption } = props;
  const { enabled, type, icon, label, required, description } = selectedOption;
  const [defaultOptions, setDefaultOptions] = useState([
    { label: 'Sample Option 1', value: 'Sample Option 1' },
    { label: 'Sample Option 2', value: 'Sample Option 2' }
  ])
  return (
    <>
      <SettingsFormGroup>
        <SettingsSwitchContainer>
          <SettingsFormLabelLeftContainer>
            Enable Custom Field
            <IconHelpV2 />
          </SettingsFormLabelLeftContainer>
          <Switch
            id="enable_custom_field"
            checked={enabled}
            onChange={(e) => setSelectedOption({
              ...selectedOption,
              enabled: e.target.checked
            })}
            variant="black" />
        </SettingsSwitchContainer>
        <p style={{ fontSize: '12px', width: '100%' }}>Show this custom field on the checkout form</p>

        <CookieColorsContainer style={{flex: 1}}>
          <CookieColorsRow>
            <SettingsFormLabelLeftContainer>Icon</SettingsFormLabelLeftContainer>
            <CookieColorPickerWrapper>
              <CookieColorPickerContent style={{ justifyContent: 'end', border: 'none' }}>
                <IconPicker
                  value={icon as IconList}
                  onChange={(value: string) => setSelectedOption({
                    ...selectedOption,
                    icon: value
                  })}
                  containerStyles={{ display: 'flex', justifyContent: 'flex-start', width: '130px' }}
                />
              </CookieColorPickerContent>
            </CookieColorPickerWrapper>
          </CookieColorsRow>
        </CookieColorsContainer>
        <p style={{ fontSize: '12px', width: '100%' }}>Show this custom field on the checkout form</p>
        <SettingsFormGroupInput>
          <SettingsFormLabelContainer>
            <SettingsFormLabelLeftContainer>Label</SettingsFormLabelLeftContainer>
            <SettingsFormLabelRightContainer>
              <IconHelp />
            </SettingsFormLabelRightContainer>
          </SettingsFormLabelContainer>
          <SettingsFormInputContainer>
            <input
              value={label}
              onChange={(e) => setSelectedOption({
                ...selectedOption,
                label: e.target.value
              })}
              placeholder="Enter Label" />
          </SettingsFormInputContainer>
          <p style={{ fontSize: '12px' }}>Name of this custom field</p>
        </SettingsFormGroupInput>
        <SettingsFormGroupInput>
          <SettingsFormLabelContainer>
            <SettingsFormLabelLeftContainer>Description</SettingsFormLabelLeftContainer>
            <SettingsFormLabelRightContainer>
              <IconHelp />
            </SettingsFormLabelRightContainer>
          </SettingsFormLabelContainer>
          <SettingsFormInputContainer>
            <input
              value={description}
              onChange={(e) => setSelectedOption({
                ...selectedOption,
                description: e.target.value
              })}
              placeholder="Enter Description" />
          </SettingsFormInputContainer>
          <p style={{ fontSize: '12px' }}>Describe this field briefly</p>
        </SettingsFormGroupInput>
        <SettingsFormGroupInput>
          <SettingsFormLabelContainer>
            <SettingsFormLabelLeftContainer>Type</SettingsFormLabelLeftContainer>
            <SettingsFormLabelRightContainer>
              <IconHelp />
            </SettingsFormLabelRightContainer>
          </SettingsFormLabelContainer>
          <SettingsFormInputContainer>
            <select
              value={type}
              onChange={(e) => setSelectedOption({
                ...selectedOption,
                type: e.target.value
              })}
            >
              <option value="dropdown">Dropdown</option>
              <option value="checkbox">Checkbox</option>
              <option value="input">Text Input</option>
            </select>
          </SettingsFormInputContainer>
        </SettingsFormGroupInput>
        <SettingsSwitchContainer>
          <SettingsFormLabelLeftContainer>
            Required
            <IconHelpV2 />
          </SettingsFormLabelLeftContainer>
          <Switch id="tax_on_prices"
            checked={required}
            onChange={(e) => setSelectedOption({
              ...selectedOption,
              required: e.target.checked
            })}
            variant="black" />
        </SettingsSwitchContainer>
        <p style={{ fontSize: '12px' }}>
          The customer has to answer this field to complete the checkout step. Apply on Dropdown and Text Input Types
        </p>

        {type == 'dropdown' && (<>
          {defaultOptions.map((option, index) => (
            <SettingsFormGroupInput>
              <SettingsFormLabelContainer>
                <SettingsFormLabelLeftContainer>#{index + 1}</SettingsFormLabelLeftContainer>
                <SettingsFormLabelRightContainer>
                  <CustomFieldButton
                    onClick={() => {
                      const copyOptions = defaultOptions
                      copyOptions.splice(index, 1)
                      setDefaultOptions([...copyOptions])
                    }}><FaTrash /></CustomFieldButton>
                </SettingsFormLabelRightContainer>
              </SettingsFormLabelContainer>
              <SettingsFormInputContainer>
                <input
                  value={option.value}
                  onChange={(e) => {
                    const copyOptions = defaultOptions
                    copyOptions[index] = {
                      label: e.target.value,
                      value: e.target.value
                    }
                    setDefaultOptions([...copyOptions])
                  }} />
              </SettingsFormInputContainer>
            </SettingsFormGroupInput>
          ))}
          <CustomFieldButtonWrapper>
            <CustomFieldButton
              onClick={() => setDefaultOptions(
                [...defaultOptions, { label: 'New Option', value: 'New Option' }]
              )}>
              <IconPlus /> Add option
            </CustomFieldButton>
          </CustomFieldButtonWrapper>
        </>
        )}
      </SettingsFormGroup>
      <SettingsActionButtonContainer style={{ zIndex: 200 }}>
        <SettingsActionButtonWrapper>
          <button type="button"
            className="default btn-cancel"
            onClick={() => handleEditCustomFieldClick(false)}>
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              handleEditCustomFieldClick(false)
              if (type === 'dropdown') {
                const copyOption = selectedOption
                copyOption.dropdown_options = defaultOptions.map(d => d.value)
                handleUpdateOption(copyOption)
              } else handleUpdateOption(selectedOption)
            }}> Update</button>
        </SettingsActionButtonWrapper>
      </SettingsActionButtonContainer>
    </>
  );
};
