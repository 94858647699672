import React, { CSSProperties, useState } from 'react';
import {
  SettingsFormGroup,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelLeftContainer,
  EligibleItemsContainer,
  EligibleItem,
  EligibleItemContentWrapper,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '..';
import { IconEligibleItemCheck, IconEligibleItemCheckDefault } from '../../../../../components/v2/icons';
import { RotateLoader } from '@lib/components';
import { RestaurantForm } from '../../../../../../mobx/components/restaurant-form';
import { useStores } from '../../../../../../mobx/useStores';
import { updateRestaurantNotificationOptions } from '../../../../../../core/libs/receipt';
import { UI } from '../../../../../../core/ui';
import { FastField } from 'formik';

interface FormValues {
  pickup: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["pickup"],
  delivery: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["delivery"],
  table_booking: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["table_booking"],
  dine_in: T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["dine_in"]
}

interface CheckboxV2Props {
  checked: boolean;
  style?: CSSProperties;
  onChange: (checked: boolean) => void
}
export const CheckboxV2 = (props: CheckboxV2Props) => {
  const { style, checked, onChange }  = props
  const [flag, setFlag] = useState(checked)
  return (
    <div style={{display: 'flex'}} {...style} onClick={() => {
      setFlag(!flag)
      onChange(!flag)
    }}>
      {flag ? <IconEligibleItemCheck /> : <IconEligibleItemCheckDefault />}
    </div>
  )
}

const customer_info = [
  { key: 'name', name: 'Name' },
  { key: 'phone', name: 'Phone' },
  { key: 'email', name: 'E-mail' },
  ];
export const CustomerInfoServices = (props: any) => {
  const store = useStores();
  const r = store.restaurant!;
  const { injected, referrer } = props

  let service:  T.Schema.Restaurant.Services.RestaurantServiceTypes = 'pickup';
  if(referrer.includes('deliveries')) {
    service = 'delivery';
  } else if(referrer.includes('dine-ins')) {
    service = 'dine_in';
  } else if(referrer.includes('table-booking')) {
    service = 'table_booking';
  }
	let initialValues = r.settings.services[service];
  console.log(referrer, service)
  return (
    <div style={{paddingBottom: 83}}>
    
    <RestaurantForm<FormValues>
      submit={async (r, values) => {
         {/*@ts-ignore*/}
        r.settings.services[service] = values;
        const update = { $set: { [`settings.services.${service}`]: r.settings.services[service] } };
        return { r, update };
      }}
      //@ts-ignore
      initialValues={initialValues}
      onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
      onError={() => UI.notification.error("An error occurred")}
      onSuccessMessage="Settings Updated"
      width='100%'
    >
      {({ form, error, getFieldError }) => {
        const { isSubmitting, setFieldValue } = form;
        return (
        <>
          <SettingsFormGroup>
            <SettingsFormGroupInput>
              <SettingsFormLabelContainer>
                <SettingsFormLabelLeftContainer style={{ alignItems: 'center' }}>
                  Required Customer Info
                </SettingsFormLabelLeftContainer>
              </SettingsFormLabelContainer>
              <EligibleItemsContainer>
                {customer_info.map((c, index) => {
                  return (
                  <EligibleItem key={index}>
                    <EligibleItemContentWrapper>
                      <FastField 
                         name={c.key}
                         render={({ field }: any) => (
                          <>
                           <CheckboxV2
                             //@ts-ignore
                              checked={form.values.customer && form.values.customer?.required_info.includes(c.key)}
                              onChange={(checked) => {
                                if(checked) {
                                  //@ts-ignore
                                  const copyRequiredInfo = form.values.customer && form.values.customer?.required_info || []
                                  setFieldValue('customer.required_info', [...copyRequiredInfo, c.key] )
                                } else {
                                  //@ts-ignore
                                  const copyRequiredInfo = form.values.customer && form.values.customer?.required_info || []
                                  copyRequiredInfo.splice(index, 1)
                                  setFieldValue('customer.required_info', [...copyRequiredInfo] )
                                  
                                }
                              }}
                            />
                            {c.name}
                        </>
                         )}
                      />
                     
                    </EligibleItemContentWrapper>
                  </EligibleItem>
                  )
                })}
              </EligibleItemsContainer>
            </SettingsFormGroupInput>
            <p style={{ fontSize: '12px' }}>
              You may choose which field will be required when placing orders. Please be aware that some or all fields may
              be required to process certain payment methods. Unchecking the info field means it will show as `Optional` on
              the checkout form.
            </p>
          </SettingsFormGroup>
          <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
        </>
      )}}
    </RestaurantForm>
  </div>
  );
};
