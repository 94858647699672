import React, { useState, useEffect } from 'react'
import { RotateLoader, DatePicker, Input, InputAddress, Switch } from '@lib/components'
import { 
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormInputContainer,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormLabelRightContainer,
    SettingsCustomOption,
    SettingsCustomOptionsContainer,
    SalesTaxesAddButton,
    OpeningHoursContainer,
    OpeningHoursCard,
    OpeningHoursCardScheduleWrapper,
    OpeningHoursCardWrapper,
    MobileModal,
    MobileModalBody,
    MobileModalContent,
    MobileModalFooter,
    MobileModalHeader,
    SettingsSwitchContainer,
    SettingsActionButtonWrapper,
    OptionalText,
    OpeningHoursCardDatesWrapper,
    SettingsActionButtonContainer
} from "."
import {
    IconHelp,
    IconCheck,
    IconHome,
    IconPlusRed,
    IconHour,
    IconClose,
    IconGreenEllipse,
    IconRedEllipse,
    IconCalendar,
    IconCalendarAlt
} from '../../../../components/v2/icons'
import { PromoCardStatus } from './promo-codes'
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { FastField } from "formik";
import { validators } from '@lib/common'
import { useStores } from '../../../../../mobx/useStores'
import { GoogleCreateService } from '../../../../../core/libs/google'
import { MapboxAPI } from '../../../../../core/libs/mapbox'
type FormValues = T.Schema.Restaurant.RestaurantSchema["location"];

const TIMESLOTS = [
    {day: 'Monday', date: 'Mar 03, 2024', active: true, hours: '9:00 - 14:00'},
    {day: 'Tuesday',date: 'Mar 03, 2024', active: false, hours: '24 Hours'},
    {day: 'Wednesday', date: 'Mar 03, 2024', active: true, hours: '24 Hours'},
]

export const defaultOpeningHour = {
    day: 'Monday',
    open: '09:00',
    close: '21:00',
    h24: false
}

export const SettingsLocation = () => {
    const [showModal, setShowModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [modal, setModal] = useState('opening')
    const store = useStores()
    const r = store.restaurant!
    let initialValues = r.location
    let GoogleService: any = {}
    useEffect(() => {
        GoogleService = GoogleCreateService();
    }, [])
    const [openingHour, setOpeningHour] = useState(defaultOpeningHour)
    const [openingHourIndex, setOpeningHourIndex] = useState(-1)
    return(
        <>
        <RestaurantForm<FormValues>
				submit={async (r, values) => {
					r.location = values;
					const update = { $set: { location: values } };
					return { r, update };
				}}
				validators={{
					address: (values) => {
						const md = values.map_data;
						if (md.type === "google_maps" || md.type === "osm") {
							if (!values.address || !md.components || !md.lat || !md.lng) {
								return { address: "Required - search and select your store address" };
							}
						}
						else if (!values.address) {
							return { address: "Required - enter your store address" };
						}
					},
					opening_hours: (values) => {
                        //@ts-ignore
						const { error } = validators.business_hours(values.opening_hours);
						if (error) {
							return { opening_hours: error };
						}
					},
				}}
				initialValues={initialValues}
				onError={() => UI.notification.error("An error occurred")}
				onSuccessMessage="Settings Updated"
			>
            {({ form, error, getFieldError }) => {
                const { isSubmitting, setFieldValue } = form;
                const mapType = form.values.map_data.type;
                return (<div style={{paddingBottom: 83}}>
                <SettingsFormGroup>
                    <SettingsFormGroupInput className='flex-full'>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Map Data Source</SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsCustomOptionsContainer>
                        <FastField
								name="map_data.type"
								render={({ field }: any) => (
                                <>
                                    {[
                                        { value: "osm", name: "Open Street Maps" },
                                        { value: "google_maps", name: "Google Maps" },
                                        { value: "custom", name: "None" },
                                    ].map((opt, index) => (
                                        <SettingsCustomOption
                                            key={index} 
                                            onClick={() => {
                                                setFieldValue("address", "");
												setFieldValue("map_data", { type: opt.value });
                                            }}
                                            className={field.value === opt.value ? 'active': ''}>
                                            {opt.name}
                                            {field.value === opt.value && <IconCheck />}
                                        </SettingsCustomOption>
                                    ))}
                                    </>
                                )} />
                        </SettingsCustomOptionsContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput className='flex-half'>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Store Address</SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer id="map-input">
                            {(mapType === "google_maps" || mapType === "osm") && (
									<InputAddress
										type={mapType}
										gm={GoogleService}
										mb={MapboxAPI}
										value={form.values.address}
										onChange={(address, map_data) => {
											setFieldValue("address", address);
											setFieldValue("map_data", map_data);
										}}
										onError={() => { }}
									/>
							)}
                            {(mapType === "custom") && (
                                <FastField
                                    name="address"
                                    render={({ field }: any) => (
                                        <input {...field} required={true} />
                                    )}
                                />
							)}
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput className='flex-half'>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Location Name (optional)</SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                            <FastField
                                name="name"
                                render={({ field }: any) => (
                                    <input {...field} />
                                )}
                            />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput className='flex-half'>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Phone Number (optional)</SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                            <FastField
								name="phone"
								render={({ field }: any) => (
                                    <input {...field} 
                                        type="tel"
                                        inputMode="numeric"
                                        onChange={(e) => {
                                        const numericValue = e.target.value.replace(/\D/g, '');
                                        form.setFieldValue('phone', numericValue);
                                        }}
                                        value={field.value}/>
								)}
							/>
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput className='flex-full'>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Opening Hours</SettingsFormLabelLeftContainer>
                            <SalesTaxesAddButton type='button' onClick={() => setShowModal(!showModal)}>
                                <IconPlusRed /> Add Time Slot
                            </SalesTaxesAddButton>
                        </SettingsFormLabelContainer>
                        <OpeningHoursContainer>
                            <p>Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open</p>
                            {form.values.opening_hours.map((times, index) => {
                                return (
                                <OpeningHoursCard onClick={() => {
                                    setEdit(true)
                                    setModal('opening')
                                    setShowModal(true)
                                    setOpeningHour(times)
                                    setOpeningHourIndex(index)
                                }}
                                key={index}>
                                    <OpeningHoursCardWrapper>
                                        {times.day}
                                        <OpeningHoursCardScheduleWrapper style={{flex: 1}}>
                                            <IconHour />
                                        {times.h24 ? '24 Hours' :
                                        `${times.open} - ${times.close}`}
                                        </OpeningHoursCardScheduleWrapper>
                                    </OpeningHoursCardWrapper>
                                </OpeningHoursCard>
                                )
                            })}
                        </OpeningHoursContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput className='flex-full'>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Special Hours <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                            <SalesTaxesAddButton
                                type="button"
                                onClick={() => {
                                setModal('special')
                                setShowModal(!showModal)}
                            }>
                                <IconPlusRed /> Add Special Hours
                            </SalesTaxesAddButton>
                        </SettingsFormLabelContainer>
                        <OpeningHoursContainer>
                            <p>Set special opening hours for holidays or other periods. This will override your regular opening hours for your store location and all services</p>
                            {TIMESLOTS.map((times, index) => {
                                return (
                                <OpeningHoursCard onClick={() => {
                                    setEdit(true)
                                    setModal('special')
                                    setShowModal(true)
                                }}
                                key={index}>
                                    <OpeningHoursCardWrapper>
                                        <OpeningHoursCardDatesWrapper>
                                            {times.date}
                                            {times.active ?<PromoCardStatus>
                                            <IconGreenEllipse />
                                            Open</PromoCardStatus>:<PromoCardStatus variant='red'>
                                            <IconRedEllipse />
                                            Closed</PromoCardStatus>}
                                        
                                        </OpeningHoursCardDatesWrapper>
                                        
                                        <OpeningHoursCardScheduleWrapper>
                                            <IconHour />
                                        {times.hours}
                                        </OpeningHoursCardScheduleWrapper>
                                    </OpeningHoursCardWrapper>
                                </OpeningHoursCard>
                                )
                            })}
                        </OpeningHoursContainer>
                    </SettingsFormGroupInput>
                </SettingsFormGroup>
                <SettingsActionButtonContainer>
                    <SettingsActionButtonWrapper>
                        <button 
                            type="button" 
                            className='btn-cancel'
                            onClick={ () => store.setActiveMobileMenu(
                                store.activeMobileMenu.referrer.pop()!,
                                Array.from(new Set(store.activeMobileMenu.referrer))
                            )}>
                            Cancel
                        </button>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                        </button>
                    </SettingsActionButtonWrapper>
                </SettingsActionButtonContainer>
                {showModal && <MobileModal>
                    <MobileModalContent>
                        <MobileModalHeader>
                            {edit ? 'Edit': 'Add'} Opening Hours
                            <span onClick={() => {
                                setShowModal(!showModal)
                                setEdit(false)
                                setModal('opening')
                            }}>
                                <IconClose />
                            </span>
                        </MobileModalHeader>
                        <MobileModalBody>
                            {modal === 'special' ? <>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Date</SettingsFormLabelLeftContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <IconCalendarAlt />
                                    <DatePicker
                                        date={new Date()}
                                        displayFormat='MMM D, Y'
                                        locale='en-AU'
                                        timezone='Australia/Brisbane'
                                        onSelect={() => {}}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Operation</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer  style={{paddingLeft: 0, paddingRight: 0, border: 'none'}}>
                                <SettingsCustomOptionsContainer style={{flexDirection: 'row', width: '100%'}}>
                                    <SettingsCustomOption className='active' style={{flex: 1}}>
                                        Open
                                        <IconCheck />
                                    </SettingsCustomOption>
                                    <SettingsCustomOption style={{flex: 1}}>Close</SettingsCustomOption>
                                </SettingsCustomOptionsContainer>
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput></>
                            : <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Day</SettingsFormLabelLeftContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer>
                                <select 
                                    value={openingHour.day}
                                    onChange={(e) => {
                                    setOpeningHour({
                                        ...openingHour,
                                        day: e.target.value
                                    })
                                }}>
                                    <option>Monday</option>
                                    <option>Tuesday</option>
                                    <option>Wednesday</option>
                                    <option>Thursday</option>
                                    <option>Friday</option>
                                    <option>Saturday</option>
                                    <option>Sunday</option>
                                </select>
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput> }
                        <SettingsSwitchContainer>
                            <SettingsFormLabelLeftContainer>
                                Open 24 Hours
                            </SettingsFormLabelLeftContainer>
                            <Switch
                                id="h24"
                                checked={openingHour.h24}
                                onChange={(e) => {
                                    setOpeningHour({
                                        ...openingHour,
                                        h24: e.target.checked
                                    })
                                }}
                                variant="black"
                                />
                        </SettingsSwitchContainer>
                        <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Open</SettingsFormLabelLeftContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer className={openingHour.h24 ? 'disabled': ''}>
                                <input 
                                    value={openingHour.open}
                                    onChange={(e) => {
                                        setOpeningHour({
                                            ...openingHour,
                                            open: e.target.value
                                        })
                                    }}
                                    disabled={openingHour.h24}
                                    placeholder='9:00' />
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput>
                        <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Close</SettingsFormLabelLeftContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer className={openingHour.h24 ? 'disabled': ''}>
                                <input
                                    value={openingHour.close}
                                    onChange={(e) => {
                                        setOpeningHour({
                                            ...openingHour,
                                            close: e.target.value
                                        })
                                    }} 
                                    disabled={openingHour.h24}
                                    placeholder='21:00' />
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput>
                        </MobileModalBody>
                        <MobileModalFooter>
                            {edit ?
                            <>
                            <SettingsActionButtonWrapper position='relative' noborder nopadding>
                                <button 
                                    type='button'
                                    onClick={() => {
                                        const copyOpeningHours = form.values.opening_hours
                                        //@ts-ignore
                                        copyOpeningHours[openingHourIndex] = openingHour
                                        setShowModal(!showModal)
                                        setEdit(false)
                                        setModal('opening')
                                        setFieldValue('opening_hours', copyOpeningHours)
                                   
                                    }}
                                    > Update</button>
                            </SettingsActionButtonWrapper>
                            <SettingsActionButtonWrapper variant="white" position='relative' noborder nopadding>
                                <button 
                                    type="button"
                                    onClick={() => {
                                        const copyOpeningHour = form.values.opening_hours[openingHourIndex]
                                        setShowModal(!showModal)
                                        setEdit(false)
                                        setModal('opening')
                                        setFieldValue('opening_hours', form.values.opening_hours.concat(copyOpeningHour as T.Core.Business.BusinessHoursSlot))
                                   
                                    }}
                                    > Duplicate</button>
                            </SettingsActionButtonWrapper>
                            <SettingsActionButtonWrapper variant="default" position='relative' noborder nopadding>
                                <button 
                                    type="button"
                                    onClick={() => {
                                        const copyOpeningHours = form.values.opening_hours
                                        copyOpeningHours.splice(openingHourIndex, 1)
                                        setShowModal(!showModal)
                                        setEdit(false)
                                        setModal('opening')
                                        setFieldValue('opening_hours', copyOpeningHours)
                                   
                                    }}> Delete</button>
                            </SettingsActionButtonWrapper>
                            </>
                            :<SettingsActionButtonWrapper 
                                position='relative' noborder nopadding>
                                <button type="button" onClick={() => {
                                    setShowModal(!showModal)
                                    setEdit(false)
                                    setModal('opening')
                                    setFieldValue('opening_hours', form.values.opening_hours.concat(openingHour as T.Core.Business.BusinessHoursSlot))
                                }}> Save</button>
                            </SettingsActionButtonWrapper> }
                        </MobileModalFooter>
                    </MobileModalContent>
                </MobileModal> }
                </div>
            )}}
            </RestaurantForm>
        </>
    )
}
