import React from 'react';
import { MobxComponent } from "../../../mobx/components";
import { inject, observer } from "mobx-react";
import { HeaderContainer, HeaderMenuWrapper, LayoutContainer, MainContainer, MenuItem, SidebarContainer, SidebarLogoWrapper, SidebarMenuWrapper, HeaderMobile, HeaderMobileTitleWrapper, HeaderMobileLogoWrapper, HeaderMobileTitle, HeaderMobileMenuContainer, HeaderMobileMenuUpperWrapper, HeaderMobileMenuLowerWrapper, HeaderMobileMenuTitleWrapper, HeaderMobileMenuWrapper, HeaderTitleWrapper, MoreActionWrapper, HeaderMobileBackWrapper } from "./layout.c";
import { IconBell, IconBookings, IconCustomers, IconDashboard, IconDocument, IconLogout, IconMenus, IconOrders, IconPayment, IconSettings, IconStore, IconHamburger, IconClose, IconBack, IconMoreOptions, IconPlusRed, IconPlus, IconQuestionCircle } from './icons';
import { withTranslation, WithTranslation } from "react-i18next";
import { routes } from '../../../routes';
import { Button, CommonText, EllipsisIcon, RotateLoader, Sidebar } from '@lib/components';
import { CoreUtils } from '@lib/common';
import { Notifications } from '../../ui/restaurant/notifications';
import { AudioUnlock } from '../AudioUnlock';
import styled from 'styled-components';
import { MoreActionSideBar } from './more-action-menu';
import { MoreActionTablet } from './more-action-menu-tablet';
import { isMobile } from 'react-device-detect';
import { BookingSettings } from '../../ui/restaurant/views/bookings/mobileBookings/modals/settings/bookingSettings';
const HeaderContainer_Height = "53px";
const PaginationWapper_Height = "72px";
import { FaPlus, FaRegQuestionCircle } from "react-icons/fa";
import { CreateCustomerModal } from '../../ui/restaurant/views/customers/mobileCustomers/customerBody/createCustomerModal';

const LayoutDashBoardWrapper = styled.div<{ customStyle?: string }>`
    height: calc(100vh - ${HeaderContainer_Height} - ${PaginationWapper_Height});
    padding: ${({ customStyle }) => (['orders', 'list', 'bump', 'settings', 'bookings', 'customers'].includes(customStyle || '') ? '0' : '1rem')};
    background-color: ${({ customStyle }) => (customStyle === 'orders' ? '#fff' : '')};

    @media (max-width: 768px) {
        padding: ${({ customStyle }) => ['orders', 'customers'].includes(customStyle || '') ? '0' : (['list', 'bump', 'bookings', 'settings', ].includes(customStyle || '') ? '57px 0 0' : '1rem')};
    }

    &.v2 {
        margin-top: 0px;
    }
`;

const HeaderRight = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

interface Props extends WithTranslation {
    children: React.ReactNode;
}
interface State {
    activeMobileMenu: boolean,
    activeSidebarMenu: boolean,
    activeMoreActionMenu: boolean,
    createCustomerModal: boolean,
}

@inject("store") @observer
class DashboardLayoutV2Class extends MobxComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activeMobileMenu: false,
            activeSidebarMenu: false,
            activeMoreActionMenu: false,
            createCustomerModal: false
        };

        this.closeMoreActionMenuSideBar = this.closeMoreActionMenuSideBar.bind(this)
    }

    componentDidMount(): void {
        this.injected.store.service.restaurant.get();
    }

    toggleMobileMenu() {
        this.setState({
            activeMobileMenu: !this.state.activeMobileMenu,
        })
    }
    toggleCustomerMenu() {
        this.setState({
            createCustomerModal: !this.state.createCustomerModal,
        })
    }

    toggleSidebarMenu() {
        this.setState({
            activeSidebarMenu: !this.state.activeSidebarMenu,
        })
    }
    openMoreActionMenuSideBar() {
        this.setState({
            activeMoreActionMenu: true,
        })
    }
    closeMoreActionMenuSideBar() {
        this.setState({
            activeMoreActionMenu: false,
        })
    }

    render() {
        const { store } = this.injected;
        const r = store.restaurant;
        const reseller = store.reseller;
        const { restrictions } = store;
        const { mobileSettingsOpen, setMobileSettingOpen } = store;
        const notificationsEnabled = restrictions._.restaurantNotificationsEnabled;
        // Should move to reseller setting section later
        let documentationLink = 'https://support.cloudwaitress.com';
        if (!r) {
            return (<RotateLoader />)
        }
        if (!reseller) {
            return (<RotateLoader />)
        }
        const logoTopNavs = r.website.sections.top_nav.images.logo;

        if (reseller._id === 'ceorestaurant') {
            documentationLink = 'https://support.ceorestaurant.my';
        }
        const { activeSidebarMenu, activeMobileMenu } = this.state;
        const shouldOnlinePaymentAllowed =
            !!r.settings.payments.stripe?.connect_account_id?.length &&
            restrictions._.onlinePaymentEnabled;
        const pathname = store.router.s.path;
        const pathNameLength = store.router.s.path.split("/").length - 1;
        const pathNameEnd = store.router.s.path.split("/")[pathNameLength];
        let links = [
            {
                id: "nav-link-home",
                tip: "Home",
                icon: <IconDashboard />,
                active: !!routes.restaurant_dashboard.pattern.match(pathname),
                onClick: () => store.router.push(`/restaurant/${r._id}`),
                allowed: restrictions.restaurant.dashboard,
            },
            {
                id: "nav-link-orders",
                tip: "Orders",
                icon: <IconOrders />,
                active: !!routes.restaurant_orders.pattern.match(pathname)
                    || !!routes.restaurant_orders_list.pattern.match(pathname)
                    || !!routes.restaurant_orders_bump.pattern.match(pathname),
                onClick: () => {
                    if (restrictions.restaurant.orders_board) {
                        store.router.push(`/restaurant/${r._id}/orders`);
                    } else if (
                        !restrictions.restaurant.orders_board &&
                        (restrictions.restaurant.orders_bump || restrictions.restaurant.orders_list)
                    ) {
                        if (restrictions.restaurant.orders_bump) {
                            store.router.push(`/restaurant/${r._id}/orders/bump`);
                        } else if (restrictions.restaurant.orders_list) {
                            store.router.push(`/restaurant/${r._id}/orders/list`);
                        }
                    }
                },
                allowed: restrictions._.restaurantOrderViews.length > 0,
            },
            {
                id: "nav-link-bookings",
                tip: "Bookings",
                icon: <IconBookings />,
                active: !!routes.restaurant_bookings.pattern.match(pathname),
                onClick: () => store.router.push(`/restaurant/${r._id}/bookings`),
                allowed: restrictions.restaurant.bookings,
            },
            {
                id: "nav-link-customers",
                tip: "Customers",
                icon: <IconCustomers />,
                active: !!routes.restaurant_customers.pattern.match(pathname),
                onClick: () => store.router.push(`/restaurant/${r._id}/customers`),
                allowed: restrictions.restaurant.customers,
            },
            {
                id: "nav-link-menus",
                tip: "Menus",
                icon: <IconMenus />,
                active: !!routes.restaurant_menus.pattern.match(pathname),
                onClick: () => store.router.push(`/restaurant/${r._id}/menus`),
                allowed: restrictions.restaurant.menus,
            },
            {
                id: "nav-link-connect",
                tip: "Online Payments",
                icon: <IconPayment />,
                active: !!routes.restaurant_stripe_connect.pattern.match(pathname),
                onClick: () => { store.router.push(`/restaurant/${r._id}/online_payments`) },
                allowed: shouldOnlinePaymentAllowed,
            },
            {
                id: "nav-link-settings",
                tip: "Settings",
                icon: <IconSettings />,
                active: !!routes.restaurant_settings.pattern.match(pathname),
                onClick: () => store.router.push(`/restaurant/${r._id}/settings`),
                allowed: restrictions._.restaurantSettingsEnabled,
            },
            {
                id: "nav-link-view-store",
                tip: "View Store",
                icon: <IconStore />,
                active: false,
                onClick: () => {
                    window.open(store.storeURL, "_blank");
                },
                allowed: restrictions._.restaurantSettingsEnabled || restrictions.restaurant.menus,
            },
            {
                id: "nav-link-documentation",
                tip: "Documentation",
                icon: <IconDocument />,
                active: false,
                onClick: () => {
                    window.open(documentationLink, "_blank");
                },
                allowed: store.showMainSupport,
            },
        ];

        const openBookingSetting = () => {
            setMobileSettingOpen(true);
        };

        const closeBookingSetting = () => {
            setMobileSettingOpen(false);
        };

        return (
            <LayoutContainer>
                <SidebarContainer className={`${activeSidebarMenu ? 'active' : ''}`}>
                    <SidebarLogoWrapper className='sidebar-logo-wrapper' isHaveLogo={logoTopNavs ? true : false}>
                        <HeaderTitleWrapper onClick={() => this.toggleSidebarMenu()}>
                            {logoTopNavs && <img src={CoreUtils.image.uc(logoTopNavs, { resize: "x150", format: "auto" })} style={{ height: "auto", maxWidth: 32, width: "100%", objectFit: "contain" }} />}
                            <span>{r.name || 'Acme Pizza'}</span>
                        </HeaderTitleWrapper>
                        <MenuItem onClick={() => this.toggleSidebarMenu()}><IconClose /></MenuItem>
                    </SidebarLogoWrapper>
                    <SidebarMenuWrapper className='sidebar-menu-wrapper'>
                        {links.map(link => !link.allowed ? null :
                            <MenuItem
                                key={link.id}
                                active={link.active}
                                onClick={link.onClick}
                                tip={link.tip}>
                                {link.icon}
                                <span>{link.tip}</span>
                            </MenuItem>
                        )}
                    </SidebarMenuWrapper>
                </SidebarContainer>
                <MainContainer>
                    <HeaderContainer>
                        <HeaderMenuWrapper>
                            <MenuItem onClick={() => {
                                store.router.push("/");
                                store.notifications.ablyRestaurantStop();
                            }}>
                                <IconBack />
                            </MenuItem>
                            <span>{r.name || 'Acme Pizza'}</span>
                        </HeaderMenuWrapper>
                        <HeaderMenuWrapper>
                            <MenuItem>
                                {notificationsEnabled && <AudioUnlock className="m-r-5" />}
                            </MenuItem>
                            <MenuItem style={{paddingBottom: 4}}>

                                {notificationsEnabled && <Notifications />}

                            </MenuItem>
                            <MenuItem onClick={() => store.service.logout()}><IconLogout /></MenuItem>
                        </HeaderMenuWrapper>
                    </HeaderContainer>
                    <HeaderMobile isMobile={isMobile} customStyle={pathNameEnd}>
                        {store.activeMobileMenu.referrer.length > 0 ?
                            <MenuItem onClick={() => {
                                if(store.activeMobileMenu.name === 'edit-custom-checkout-field') {
                                    let service =  store.activeMobileModal.split('|')[0]
                                    if (service.includes('delivery')) {
                                        service = 'deliveries';
                                      } else if (service.includes('dine_in')) {
                                        service = 'dine-ins';
                                      } else if (service.includes('table_booking')) {
                                        service = 'table-booking';
                                      }
                                    store.setActiveMobileMenu(
                                        store.activeMobileMenu.referrer.pop()!,
                                        Array.from(new Set(store.activeMobileMenu.referrer.concat(service)))
                                        
                                    );
                                    store.setActiveMobileModal('');  
                                } else {
                                    store.setActiveMobileMenu(
                                        store.activeMobileMenu.referrer.pop()!,
                                        Array.from(new Set(store.activeMobileMenu.referrer))
                                    )
                                }
                            }}>
                                <IconBack />
                            </MenuItem>
                            : <MenuItem
                                onClick={() => this.toggleMobileMenu()}>
                                <IconHamburger />
                            </MenuItem>}
                        <HeaderMobileTitleWrapper isDevelopers>
                            {logoTopNavs && <HeaderMobileLogoWrapper>
                                <img src={CoreUtils.image.uc(logoTopNavs, { resize: "x150", format: "auto" })} style={{ maxWidth: "32px", height: 'auto', width: '100%', objectFit: 'contain' }} />
                            </HeaderMobileLogoWrapper>}
                            <HeaderMobileTitle>
                                {store.activeMobileMenu.referrer.length > 0 ?
                                    store.activeMobileMenu.name.replace(/-/g, ' ')
                                    : links.map(link => !link.active ? null :
                                        <p key={link.id}>{link.tip}</p>
                                    )}
                            </HeaderMobileTitle>
                        </HeaderMobileTitleWrapper>
                        <MenuItem>
                            {pathNameEnd === 'menus' &&
                                <MoreActionWrapper ><div className='tablet' onClick={() => this.openMoreActionMenuSideBar()}><EllipsisIcon /></div> <div className='mobile'><MoreActionTablet /></div></MoreActionWrapper>}
                            {/* {notificationsEnabled && store.activeMobileMenu.referrer.length == 0 && <Notifications />} */}
                            {store.activeMobileMenu.name == 'edit-promo' &&
                                <span onClick={() => store.setActiveMobileModal('edit-promo')}>
                                    <IconMoreOptions />
                                </span>
                            }
                             {store.activeMobileMenu.name == 'edit-webhooks' &&
                                <span onClick={() => store.setActiveMobileModal('edit-webhooks')}>
                                    <IconMoreOptions />
                                </span>
                            }
                            {store.activeMobileMenu.name == 'edit-conditional-fees' &&
                                <span onClick={() => store.setActiveMobileModal('edit-conditional-fees')}>
                                    <IconMoreOptions />
                                </span>
                            }
                            {store.activeMobileMenu.name == 'printer-setup' &&
                                <span style={{ height: 24 }} onClick={() => store.setActiveMobileMenu('add-printer', 'printer-setup')}>
                                    <IconPlusRed width={24} height={24} />
                                </span>
                            }
                            {store.activeMobileMenu.name == 'edit-printer' &&
                                <span onClick={() => store.setActiveMobileModal('edit-printer')}>
                                    <IconMoreOptions />
                                </span>
                            }
                            {store.activeMobileMenu.name == 'webhooks' &&
                                <span style={{ height: 24 }} onClick={() => store.setActiveMobileMenu('create-webhooks', 'webhooks')}>
                                    <IconPlusRed width={24} height={24} />
                                </span>
                            }
                            {
                                pathNameEnd === 'bookings' && (
                                    <IconSettings onClick={openBookingSetting} />
                                )
                            }
                            {
                                pathNameEnd === 'customers' && (
                                    <>
                                        <Button size='xxs' display='flex' type="button" className="no-round" paddinglr={5} color="transparent" onClick={() => { this.toggleCustomerMenu() }}>
                                            <IconPlus />
                                        </Button>
                                        <Button size='xxs' display='flex' type="button" className="no-round" paddinglr={5} color="transparent" onClick={() => { }}>
                                            <IconQuestionCircle />
                                        </Button>
                                    </>
                                )
                            }

                        </MenuItem>

                        <Sidebar
                            isOpen={this.state.activeMoreActionMenu}
                            headerTitle={"More Action"}
                            onClose={() => this.closeMoreActionMenuSideBar()}
                            width={window.innerWidth}>
                            <MoreActionSideBar onClose={this.closeMoreActionMenuSideBar} />
                        </Sidebar>
                        <Sidebar
                            isOpen={this.state.createCustomerModal}
                            headerTitle={"Customer - Create"}
                            onClose={() => this.toggleCustomerMenu()}
                            width={window.innerWidth}
                            version='v2'
                        >
                            <CreateCustomerModal onClose={this.toggleCustomerMenu} />
                        </Sidebar>

                        <BookingSettings isOpen={mobileSettingsOpen} handleClose={closeBookingSetting} />
                    </HeaderMobile>
                    <HeaderMobileMenuContainer className={`${activeMobileMenu ? 'active' : ''}`}>
                        <HeaderMobileMenuUpperWrapper>
                            <HeaderMobileMenuTitleWrapper>
                                <HeaderMobileTitleWrapper className='HeaderMobileTitleWrapper'>
                                    {logoTopNavs && <HeaderMobileLogoWrapper>
                                        <img src={CoreUtils.image.uc(logoTopNavs, { resize: "x150", format: "auto" })} style={{ maxHeight: "32px" }} />
                                    </HeaderMobileLogoWrapper>}
                                    <HeaderMobileTitle>
                                        {r.name || 'Acme Pizza'}
                                    </HeaderMobileTitle>
                                </HeaderMobileTitleWrapper>
                            
                                <MenuItem onClick={() => this.toggleMobileMenu()}>
                                    <IconClose />
                                </MenuItem>
                            </HeaderMobileMenuTitleWrapper>
                            <HeaderMobileBackWrapper>
                                <MenuItem onClick={() => {
                                    store.router.push("/");
                                    store.notifications.ablyRestaurantStop();
                                }}>
                                    <IconBack /> 
                                    <CommonText size='large' color='#4B4F5E' weight={400}>
                                        Back to Restaurant List
                                    </CommonText>
                                </MenuItem>
                            </HeaderMobileBackWrapper>
                            <HeaderMobileMenuWrapper>
                                {links.map(link => !link.allowed ? null :
                                    <MenuItem
                                        key={link.id}
                                        active={link.active}
                                        tip={link.tip}
                                        onClick={() => {
                                            this.setState({
                                                activeMobileMenu: false
                                            }, () => {
                                                store.setActiveMobileMenu('settings')
                                                link.onClick()
                                            })

                                        }}>
                                        {link.icon}
                                        <p>{link.tip}</p>
                                    </MenuItem>
                                )
                                }
                            </HeaderMobileMenuWrapper>
                        </HeaderMobileMenuUpperWrapper>
                    </HeaderMobileMenuContainer>
                    <LayoutDashBoardWrapper customStyle={pathNameEnd}>
                        {this.props.children}
                    </LayoutDashBoardWrapper>
                </MainContainer >
            </LayoutContainer >
        )
    }
}

export const DashboardLayoutV2 = withTranslation()(DashboardLayoutV2Class);
