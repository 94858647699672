import React from 'react';
import styled from 'styled-components';

export const ListContainer = styled.div`
    flex: 1;
    background: #F1F4F7;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

`;

export const CustomerBodyWrapper = styled.div`
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .mobile-view {
        display: none;
    }
    .desktop-view {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
    }
    @media (max-width: 767px) {
        .mobile-view {
            width: 100%;
            display: block;
        }
        .desktop-view {
            display: none;
        }
    }
`;

export const CustomerDetailBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #F1F4F7;
  padding: 16px !important;
  width: 100%;
  height: calc(100% - 57px);
  overflow-y: auto;
  @media (min-width: 767px) {
        max-width: 865px;
        overflow-y: unset;
        height: 100%;
    }
`;

export const CardLeft = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #0057e7;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StatisticsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export const IdVerificationConfirmWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StatisticsItem = styled.div`
  background: #FEFEFE;
  padding: 16px;
  border-radius: 4px;
`;
export const StaticsticItemHeader = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #6B7270;
`;
export const StaticsticItemText = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #4B4F5E;
`;

export const CustomerModalWrapper = styled.div<{ bg?: string; }>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: ${({ bg }) => bg ? bg : '#F1F4F7'};
    z-index: 2;
`;


export const FilterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 8px;
    background: #FEFEFE;
`;

export const SortByWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;