import React, { useState } from "react"
import { Flex } from "../../../../../components/Flex"
import { CardLeft, CustomerDetailBody, IdVerificationConfirmWrapper, StaticsticItemHeader, StaticsticItemText, StatisticsItem, StatisticsWrapper } from "../style"
import { ForkAndKniveIcon, GuestIcon, IconClose, KniveAndSpoonIcon, LogIcon, LoginIcon, MailIcon, MotorcycleIcon, PhoneIcon, SalesIcon, ShoppingBagIcon, StarIcon, TrashIcon, UnverifiedIcon, VerifiedIcon } from "../../../../../../../components/v2/icons"
import { Button, CommonText, EllipsisIcon, FormGroup, Input, LinkTag, RotateLoader } from "@lib/components"
import { ButtonWrapper } from "../../../../../components/mobileControls"
import ReactAvatar from "react-avatar"
import { Thumbnail } from "../../customerCard/Card"
import { useTranslation } from "react-i18next"
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalFooter, MobileModalHeader } from "../../../../settings-v2"
import { GroupItemsTick } from "../../../../../components/groupItemsTick"
import { MobXProviderContext } from "mobx-react"
import { IdVerification } from "../idVerification"

const customerType: customerTypeProps  = {
    'guest': 'Guest'
}
interface optionType {
    id: string;
    label: string;
    value: string;
    icon: () => JSX.Element;
}
interface customerTypeProps {
    'guest': string;
}

const options = [
    {
        id: 'edit_email',
        label: 'Edit Email',
        value: 'edit_email',
        icon: <MailIcon size={24} color="#4B4F5E" />
    },
    {
        id: 'edit_password',
        label: 'Edit Password',
        value: 'edit_password',
        icon: <LogIcon />
    },
    {
        id: 'delete_customer',
        label: 'Delete Customer',
        value: 'delete_customer',
        icon: <TrashIcon />
    },
];

type CustomerDetailProps = {
    
}

export const statusDisplay = (status: any) => {
    switch (status) {
        case 'verified':
            return(
                <>
                    <VerifiedIcon />
                    <CommonText size="small" color="#009100">
                        Verified
                    </CommonText>
                </>
            )
        case 'pending':
            return(
                <>
                    <UnverifiedIcon />
                    <CommonText size="small" color="#009100">
                        Pending
                    </CommonText>
                </>
            )
        case 'approved':
            return(
                <>
                    <VerifiedIcon />
                    <CommonText size="small" color="#009100">
                        Approved
                    </CommonText>
                </>
            )
        default:
            return(
                <>
                    <UnverifiedIcon />
                    <CommonText size="small" color="#BC362F">
                        Unverified
                    </CommonText>
                </>
            )
            break;
    }
    
}
export const CustomerDetail: React.FC<CustomerDetailProps> = () => {
    const { t } = useTranslation();
    const { store } = React.useContext(MobXProviderContext);
    const { currentCustomer} = store.customerControl;
    const { details, type, stats, delivery, meta, created, verified } = currentCustomer;
    const [showModal, setShowModal] = useState(false)
    
    const [activeItem, setActiveItem] = useState<optionType | undefined>(undefined);
    const r = store.restaurant!;
    const c = store.customer!;
	const av = r.settings.business.age_verification;

    const timeDisplay = (time: number) => {
        return `${t("dateFromTimestamp", { value: time })}, ${t("timeFromTimestamp", { value: time })}`
    }

    const handleSelectItem = (item: any) => {
        setActiveItem(item);
    };

    return (
        <>
            <CustomerDetailBody>
                <Flex jc="space-between" m={'0'}>
                    <Flex m="0" f="1" jc="flex-start">
                        <Flex>
                            <Thumbnail>
                                <ReactAvatar name={details?.name} email={details.email} size="48" />
                            </Thumbnail>
                        </Flex>
                        <Flex m="0 0 0 10px" f='1' fd="column" jc="flex-start">
                            <CommonText size="medium" weight={600} color="#0C202A">
                                {details.name}
                            </CommonText>
                            <CommonText size="semiMedium" weight={500} color="#4B4F5E">
                                {customerType[type as keyof customerTypeProps]}
                            </CommonText>
                        </Flex>

                    </Flex>

                    <ButtonWrapper bg={'white'} onClick={() => { setShowModal(true) }}>
                        <EllipsisIcon />
                    </ButtonWrapper>
                </Flex>

                <Flex fd="column" jc="start" g="16px">
                    <CommonText size="medium" weight={600} color="#4B4F5E">
                        Contacts
                    </CommonText>
                    <Flex jc="space-between" ai="center" bg="#FEFEFE" p="16px" br='4px'>
                        <CommonText size="semiMedium" weight={500} color="#4B4F5E">
                            {details.email ? details.email : '-'}
                        </CommonText>
                        <ButtonWrapper>
                            <MailIcon />
                        </ButtonWrapper>
                    </Flex>
                    <Flex jc="space-between" ai="center" bg="#FEFEFE" p="16px" br='4px'>
                        <CommonText size="semiMedium" weight={500} color="#4B4F5E">
                            {details.phone}
                        </CommonText>
                        <ButtonWrapper>
                            <PhoneIcon />
                        </ButtonWrapper>
                    </Flex>
                </Flex>

                <Flex fd="column" jc="start" g="16px">
                    <CommonText size="medium" weight={600} color="#4B4F5E">
                        Statistics
                    </CommonText>
                    <StatisticsWrapper >
                        <StatisticsItem>
                            <StaticsticItemHeader>
                                <SalesIcon />Total Sales
                            </StaticsticItemHeader>
                            <StaticsticItemText>{t("currency", { value: stats.sales })}</StaticsticItemText>
                        </StatisticsItem>
                        <StatisticsItem>
                            <StaticsticItemHeader>
                                <ShoppingBagIcon />Total Orders
                            </StaticsticItemHeader>
                            <StaticsticItemText>{stats.orders}</StaticsticItemText>
                        </StatisticsItem>
                        <StatisticsItem>
                            <StaticsticItemHeader>
                                <LoginIcon />
                                Total Sessions
                            </StaticsticItemHeader>
                            <StaticsticItemText>{stats.sessions ? stats.sessions : 0}</StaticsticItemText>
                        </StatisticsItem>
                        <StatisticsItem>
                            <StaticsticItemHeader>
                                <MotorcycleIcon />
                                Successful Deliveries
                            </StaticsticItemHeader>
                            <StaticsticItemText>{stats.deliveries ? stats.deliveries : 0}</StaticsticItemText>
                        </StatisticsItem>
                        <StatisticsItem>
                            <StaticsticItemHeader>
                                <KniveAndSpoonIcon />
                                Successful Orders
                            </StaticsticItemHeader>
                            <StaticsticItemText>{stats.orders ? stats.orders : 0}</StaticsticItemText>
                        </StatisticsItem>
                        {/* <StatisticsItem>
                            <StaticsticItemHeader>
                                <StarIcon />
                                Rank
                            </StaticsticItemHeader>
                            <StaticsticItemText>{stats.rank ? stats.rank : 0}</StaticsticItemText>
                        </StatisticsItem> */}
                    </StatisticsWrapper>
                </Flex>
                <Flex fd="column" jc="start" g="16px">
                    <CommonText size="medium" weight={600} color="#4B4F5E">
                        Details
                    </CommonText>
                    <Flex jc="space-between" ai="center" bg="#FEFEFE" p="16px" fd="column" g='16px'>
                        <Flex jc="space-between" w="100%">
                            <CommonText size="semiMedium" color="#6B7270">E-mail Verified</CommonText>
                            <Flex w="fit-content" p="0px 8px" g="8px" bg='#F1F8E8' br={'4px'}>
                                {statusDisplay(verified ? 'verified' : 'unverified')}
                            </Flex>
                        </Flex>
                        
                        <Flex jc="space-between" w="100%">
                            <CommonText size="semiMedium" color="#6B7270">Created</CommonText>
                            <CommonText size="semiMedium" color="#4B4F5E" weight={500}>{created ? timeDisplay(created) : '-'}</CommonText>
                        </Flex>
                        <Flex jc="space-between" w="100%">
                            <CommonText size="semiMedium" color="#6B7270">Last Seen</CommonText>
                            <CommonText size="semiMedium" color="#4B4F5E" weight={500}>{meta?.last_seen ? timeDisplay(meta?.last_seen) : '-'}</CommonText>
                        </Flex>
                        <Flex jc="space-between" w="100%">
                            <CommonText size="semiMedium" color="#6B7270">Last Order</CommonText>
                            <CommonText size="semiMedium" color="#4B4F5E" weight={500}>{meta?.last_order ? timeDisplay(meta?.last_order) : '-'}</CommonText>
                        </Flex>
                        <Flex jc="space-between" w="100%">
                            <CommonText size="semiMedium" color="#6B7270">Last IP Adress</CommonText>
                            <CommonText size="semiMedium" color="#4B4F5E" weight={500}>{meta?.last_ip ? meta?.last_ip : '-'}</CommonText>
                        </Flex>
                        <Flex jc="space-between" w="100%">
                            <CommonText size="semiMedium" color="#6B7270">Delivery Address</CommonText>
                            <CommonText size="semiMedium" color="#4B4F5E" weight={500}>{delivery?.destination ? delivery?.destination : '-'}</CommonText>
                        </Flex>
                    </Flex>
                </Flex>
                {av.enabled && <IdVerification />}
                

            </CustomerDetailBody>
            {showModal && <MobileModal>
                <MobileModalContent>
                    <MobileModalHeader>
                        <CommonText size="medium" color="#4B4F5E">{activeItem?.label ? activeItem?.label : 'More Actions'} </CommonText>
                        <span onClick={() => {
                            setShowModal(prev => !prev)
                            setActiveItem(undefined)
                        }}>
                            <IconClose />
                        </span>
                    </MobileModalHeader>
                    <MobileModalBody>
                        {activeItem?.id === 'edit_email' &&
                            <Input
                                placeholder={`Enter a new e-mail address for the customer`}
                                required={true}
                            // value={item.up_to}
                            // onChange={(e: any) => this.change(i, "up_to", parseFloat(e.target.value) || 0)} 
                            />
                        }
                        {activeItem?.id === 'edit_password' &&
                            <>
                                <Input
                                    placeholder={`Enter password`}
                                    required={true}
                                // value={item.up_to}
                                // onChange={(e: any) => this.change(i, "up_to", parseFloat(e.target.value) || 0)} 
                                />
                                <CommonText size="semiMedium" color="#6B7270">Enter a new password for the customer. Minimum 6 characters. This will log the customer out</CommonText>
                            </>
                        }
                        {activeItem?.id === 'delete_customer' &&
                            <CommonText size="semiMedium" color="#6B7270">Once deleted, it cannot be recovered. Delete the customer?</CommonText>
                        }
                        {!activeItem?.id && <GroupItemsTick options={options} activeId={activeItem?.id} selectItem={handleSelectItem} />}


                    </MobileModalBody>
                    <MobileModalFooter>
                        {(activeItem?.id === 'edit_email' || activeItem?.id === 'edit_password') ?
                            <Button color="primary" full={true}>Update</Button>
                            : activeItem?.id === 'delete_customer' ?
                                <Button color="primary" full={true}>Confirm</Button>
                                : ''
                        }
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal>}
        </>
    )

}