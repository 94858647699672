import React, { forwardRef, useEffect, useState } from "react";
import { 
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormLabelRightContainer,
    SettingsFormInputContainer,
    SettingsSwitchContainer,
    OptionalText,
    SettingsActionButtonWrapper,
    SettingsActionButtonContainer
} from ".";
import { 
    IconDrag,
    IconHelp
} from "../../../../components/v2/icons";
import { InputTags, RotateLoader, SelectAdv, Switch, SwitchListSortablev2 } from "@lib/components";
import { useStores } from "../../../../../mobx/useStores";
import { 
    PrinterCard,
    PrinterCardContainer,
    PrinterCardHeader,
    PrinterCardContent,
    PrinterCardBody,
    PrinterCardBodyDetails,
    TabGroup,
    TabItem
} from "./receipt-printing";
import styled from "styled-components";
import { SettingsFormGroupEditor } from "./policies-agreements";
import Quill from "quill";

import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { FastField } from "formik";
import { SortType, print_detail_list_customer } from "@lib/common";
import { cloneDeep } from "lodash";
import validator from "validator";
import { getOptionalOverviewReportEmail } from "../../../../utils/overview_report_email";
import { arrayMove } from "react-sortable-hoc";
import { transfromCheckoutFieldsToReceiptPrintOptions } from "../../../../../core/libs/receipt";

export const ImageLogoContainer = styled.div`
display: flex;
width: 100%;
height: 218px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 4px;
border: 1px dashed var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Neutral-Gray, #F1F4F7);

& p {
    color: var(--Secondary-Smokey-Grey-1, #6B7270);
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
& input, & button {
    border-radius: 4px;
border: 1px solid var(--Primary-Red-Orange-1, #FB2F2F);
color: var(--Primary-Red-Orange-1, #FB2F2F);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 114.286% */
display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 4px;
}

& a.inline-block {
    display: none!important;
}
`
export const ImageLogoWrapper = styled.div`
display: flex;
width: 168px;
height: 168px;
padding: 16.8px;
justify-content: center;
align-items: center;
flex-shrink: 0;

& img, & svg {
    width: 100%;
}
`

export const CustomizationContainer = styled.div`
display: flex;
width: 100%;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
border-radius: 4px;
border: 1px dashed var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Neutral-Gray, #F1F4F7);

& div[class^="scroll-list__ScrollListComponent"] {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: none;
    background: transparent;
}
`

export const CustomizationItem = styled.div`
display: flex;
padding: 4px 8px;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
background: var(--Neutral-White, #FEFEFE);
`
type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["notifications"];

interface SimpleEditorProps  {
    id: string;
    onChange: (val: string) => void;
    initialValue?: string;
}

export const SimpleEditor = (props: SimpleEditorProps)=> {
    const { id, onChange, initialValue } = props
    useEffect(() => {
       const quill = new Quill('#'+id, {
            modules: {
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline'],
                ['image', 'code-block'],
              ],
            },
            placeholder: 'Sample Text',
            theme: 'snow', // or 'bubble',
          })
        quill.root.innerHTML = initialValue || "";
        quill.on('text-change', (_delta, _oldDelta, source) => {
            if(source === 'user' || source === 'api') {
                onChange(quill.root.innerHTML)
            }
        })
    }, [])
    return (
        <div id={id}></div>
        
    )
}

export const SettingsEmailNotification= (props: any) => {
    const [toggleSwitch, setToggleSwitch] = useState<boolean>(false)
    const store = useStores()
    const { activeMobileMenu, injected } = props
    const { setActiveMobileMenu, setActiveMobileModal } = store
    const [activeTab, setActiveTab] = useState<string>('store')
    const r = injected.store.restaurant!
    let initialValues = cloneDeep(r.settings.notifications)
    const [currentLogo, setCurrentLogo] = useState<T.Core.Business.BusinessImage | undefined | null>(r!.website.sections.top_nav.images.logo)
    const optionalOverviewReportEmail = getOptionalOverviewReportEmail(r.settings.notifications.email.store)
    
    const handleClearEditor = (quill: Quill) => {
        quill.setText('')
        quill.root.innerHTML = ''
    }
    return (
        <div style={{ paddingBottom: 83, width: '100%'}}> 
          
            <TabGroup>
                <TabItem
                    onClick={() => setActiveTab('store')}
                    active={activeTab === 'store'}
                    style={{flex: 1}}>Store</TabItem>
                <TabItem
                    onClick={() => setActiveTab('customer')}
                    active={activeTab === 'customer'}
                    style={{flex: 1}}>Customer</TabItem>
                <TabItem
                    onClick={() => setActiveTab('pdf-receipt')}
                    active={activeTab === 'pdf-receipt'}
                    style={{flex: 1}}>PDF Receipt</TabItem>
            </TabGroup>

            <RestaurantForm<FormValues>
					submit={async (r, v) => {
						r.settings.notifications = v;
						const update = {
							$set: { "settings.notifications": v },
						};
						return { r, update };
					}}
					initialValues={initialValues}
					onError={() => UI.notification.error("An error occurred")}
					onSuccessMessage="Settings Updated"
                    width="100%"
				>
					{({ form, error }) => {
						const { isSubmitting, setFieldValue } = form;

						return (<>
                        <SettingsFormGroup style={{gap: 16, paddingBottom: 32}}>
                            {activeTab === 'store' && <>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Notification Reply-To Email <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <FastField
                                        name="email.store.support_reply_to"
										render={({ field }: any) => (
                                        <input 
                                            type="email"
                                            placeholder="Enter an e-mail address"
                                            onChange={(
                                                email
                                            ) => {
                                                validator.isEmail(
                                                    email.target
                                                        .value
                                                )
                                                    ? setFieldValue(
                                                            "email.store.support_reply_to",
                                                            email
                                                                .target
                                                                .value
                                                    )
                                                    : "";
                                            }}
                                            {...field}/>
                                     )}/>
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Notification E-mail Addresses <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <FastField
                                        name="email.store.addresses"
                                        render={({ field }: any) => (
                                            <InputTags
                                                    max={3}
                                                    placeholder="Enter an e-mail and press enter"
                                                    validator={(val) =>
                                                        validator.isEmail(
                                                            val
                                                        )
                                                            ? null
                                                            : "Invalid e-mail address"
                                                    }
                                                    onError={(e) =>
                                                        UI.notification.error(
                                                            e,
                                                            {
                                                                timeout: 5000,
                                                            }
                                                        )
                                                    }
                                                    tags={field.value}
                                                    onChange={(tags) =>
                                                        setFieldValue(
                                                            "email.store.addresses",
                                                            tags
                                                        )
                                                    }
                                                />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Overview Report E-Mail Addresses <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                <FastField
                                    name="email.store.overview_report_emails"
                                    render={({ field }: any) => (
                                    
                                            <InputTags
                                                max={4}
                                                placeholder="Enter an e-mail and press enter"
                                                validator={(val) =>
                                                    validator.isEmail(
                                                        val
                                                    )
                                                        ? null
                                                        : "Invalid e-mail address"
                                                }
                                                onError={(e) =>
                                                    UI.notification.error(
                                                        e,
                                                        {
                                                            timeout: 5000,
                                                        }
                                                    )
                                                }
                                                tags={
                                                    field.value || optionalOverviewReportEmail
                                                }
                                                onChange={(tags) =>
                                                    setFieldValue(
                                                        "email.store.overview_report_emails",
                                                        tags
                                                    )
                                                }
                                            />
                                    )}
                                />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                    Order Created
                                </SettingsFormLabelLeftContainer>
                                <FastField 
                                    name="email.store.order_created"
                                    render={({ field }: any) =>
                                    <Switch
                                        id="email.store.order_created"
                                        checked={field.value || false}
                                        onChange={(e) =>  setFieldValue("email.store.order_created", e.target.checked)}
                                        variant="black"
                                        />
                                    }
                                />
                            </SettingsSwitchContainer>
                            <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                    Booking Created
                                </SettingsFormLabelLeftContainer>
                                <FastField 
                                    name="email.store.booking_created"
                                    render={({ field }: any) =>
                                    <Switch
                                        id="email.store.booking_created"
                                        checked={field.value || false}
                                        onChange={(e) =>  setFieldValue("email.store.booking_created", e.target.checked)}
                                        variant="black"
                                        />
                                    }
                                />
                            </SettingsSwitchContainer>
                            <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                    Age Verification
                                </SettingsFormLabelLeftContainer>
                                <FastField 
                                    name="email.store.age_verification"
                                    render={({ field }: any) =>
                                    <Switch
                                        id="email.store.age_verification"
                                        checked={field.value || false}
                                        onChange={(e) =>  setFieldValue("email.store.age_verification", e.target.checked)}
                                        variant="black"
                                        />
                                    }
                                />
                            </SettingsSwitchContainer>
                            </>}
                            {activeTab === 'customer' && <>
                            <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                    Order Created
                                </SettingsFormLabelLeftContainer>
                                <FastField 
                                    name="email.customer.order_created.enabled"
                                    render={({ field }: any) =>
                                    <Switch
                                        id="email.customer.order_created.enabled"
                                        checked={field.value || false}
                                        onChange={(e) =>  setFieldValue("email.customer.order_created.enabled", e.target.checked)}
                                        variant="black"
                                        />
                                    }
                                />
                            </SettingsSwitchContainer>
                            </>}
                            {activeTab === 'pdf-receipt' && <>
                            <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                    Print Logo
                                </SettingsFormLabelLeftContainer>
                                <FastField 
                                    name="email.customer.logo"
                                    render={({ field }: any) =>
                                    <Switch
                                        id="email.customer.logo"
                                        checked={field.value || false}
                                        onChange={(e) =>  setFieldValue("email.customer.logo", e.target.checked)}
                                        variant="black"
                                        />
                                    }
                                />
                            </SettingsSwitchContainer>
                            <ImageLogoContainer>
                                <ImageLogoWrapper>
                                <svg xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 136 136" fill="none">
                                    <path d="M135.2 67.9998C135.2 105.113 105.113 135.2 67.9998 135.2C30.8863 135.2 0.799805 105.113 0.799805 67.9998C0.799805 30.8863 30.8863 0.799805 67.9998 0.799805C105.113 0.799805 135.2 30.8863 135.2 67.9998Z" fill="#FB2F2F"/>
                                    <path d="M57.5149 30.3486C48.5221 33.9128 49.0842 42.1667 49.6462 45.9185C42.3396 45.7309 38.4053 47.2316 35.4077 51.3586C32.7896 54.963 32.5974 60.9256 33.1595 63.7395C30.5366 63.7395 18.9209 68.054 18.9209 82.4984C18.9209 94.0539 28.8504 100.507 34.8456 101.07H46.836C48.0225 103.008 51.9694 106.885 58.2643 106.885C64.5592 106.885 68.5061 103.008 69.6926 101.07H99.6686C104.415 100.82 114.469 97.318 116.717 85.3123C118.966 73.3065 110.16 66.4283 105.476 64.4898C107.912 61.1132 110.16 51.3808 103.416 45.5433C94.5726 37.8897 85.3675 42.9796 81.8703 46.4813C79.8095 42.9171 75.3881 35.4885 74.189 34.288C72.6902 32.7872 67.2607 26.4859 57.5149 30.3486Z" fill="#FFF3F3"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M74.5685 70.466C76.113 68.8411 77.0609 66.6436 77.0609 64.2247C77.0609 59.2207 73.0043 55.1641 68.0002 55.1641C62.9961 55.1641 58.9395 59.2207 58.9395 64.2247C58.9395 66.6436 59.8874 68.8411 61.4319 70.466L60.1233 72.6872C59.5164 72.1215 58.7021 71.7753 57.8069 71.7753C55.9304 71.7753 54.4092 73.2965 54.4092 75.173C54.4092 76.6898 55.403 77.9745 56.7751 78.4113C54.8635 82.3556 57.7221 87.0652 62.2221 87.0652H73.7782C78.3237 87.0652 81.1945 82.2601 79.1659 78.292C80.3708 77.7698 81.2137 76.5699 81.2137 75.173C81.2137 73.2965 79.6925 71.7753 77.8159 71.7753C77.0394 71.7753 76.3238 72.0358 75.7516 72.4741L74.5685 70.466Z" fill="#FB2F2F"/>
                                    </svg>
                                </ImageLogoWrapper>
                            </ImageLogoContainer>
                            <p>Take from the current restaurant's logo (Settings {'>'} Website {'>'} Top Nav Bar {'>'} Logo Image). It may not print well with the colorful/multi-shades logo. The logo won’t work on ESCPOS text mode.</p>
                            <SettingsFormGroupInput style={{gap: 8, paddingBottom: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)'}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Receipt Header <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormGroupEditor style={{padding: 0}}>
                                    <FastField
                                        name="email.customer.header"
                                        render={({
                                            field,
                                        }: any) => (
                                            <SimpleEditor
                                                id="receipt-header-editor"
                                                onChange={(val) =>
                                                    setFieldValue(
                                                        "email.customer.header",
                                                        val
                                                    )
                                                }
                                                initialValue={field.value}
                                            />
                                        )}
                                    />
                                </SettingsFormGroupEditor>
                                <SettingsActionButtonWrapper
                                    onClick={() => {
                                        const headerEditor = document.querySelector('#receipt-header-editor')
                                        if(headerEditor) {
                                            Quill.find(headerEditor).setText('')
                                        }
                                    }}
                                    variant="white"
                                    position='relative'
                                    nopadding>
                                    <button type="button"> Clear</button>
                                </SettingsActionButtonWrapper>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput style={{gap: 8, paddingBottom: 16, borderBottom: '1px dashed var(--Secondary-Light-Grey-1, #DFDFDF)'}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Receipt Footer <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormGroupEditor style={{padding: 0}}>
                                    <FastField
                                        name="email.customer.footer"
                                        render={({
                                            field,
                                        }: any) => (
                                            <SimpleEditor
                                                id="receipt-footer-editor"
                                                onChange={(val) =>
                                                    setFieldValue(
                                                        "email.customer.footer",
                                                        val
                                                    )
                                                }
                                                initialValue={field.value}
                                            />
                                        )}
                                    />
                                </SettingsFormGroupEditor>
                                <SettingsActionButtonWrapper 
                                    onClick={() => {
                                        const footerEditor = document.querySelector('#receipt-footer-editor')
                                        if(footerEditor) {
                                            Quill.find(footerEditor).setText('')
                                        }
                                    }}
                                    variant="white"
                                    position='relative'
                                    nopadding>
                                    <button type="button"> Clear</button>
                                </SettingsActionButtonWrapper>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput style={{gap: 8}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Detail Customization</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <CustomizationContainer>
                                <FastField
                                        name="email.customer.order_created.details"
                                        render={({
                                            field,
                                        }: any) => (
                                            <SwitchListSortablev2
                                                height={450}
                                                items={field.value}
                                                onCheck={(
                                                    i,
                                                    item
                                                ) => {
                                                    const items = [
                                                        ...field.value,
                                                    ];
                                                    items[
                                                        i
                                                    ].enabled =
                                                        !item.enabled;
                                                    setFieldValue(
                                                        "email.customer.order_created.details",
                                                        items
                                                    );
                                                }}
                                                units={null}
                                                onSortEnd={({
                                                    oldIndex,
                                                    newIndex,
                                                }) => {
                                                    setFieldValue(
                                                        "email.customer.order_created.details",
                                                        arrayMove(
                                                            field.value,
                                                            oldIndex,
                                                            newIndex
                                                        )
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </CustomizationContainer>
                                <SettingsActionButtonWrapper variant="white" position='relative'  nopadding>
                                    <button
                                        onClick={() => {
                                            const options =
                                               transfromCheckoutFieldsToReceiptPrintOptions(
                                                    injected
                                                        .store
                                                        .restaurant!,
                                                    print_detail_list_customer()
                                                );
                                            setFieldValue(
                                                "email.customer.order_created.details",
                                                options
                                            );
                                        }} 
                                        type="button"> Reset</button>
                                </SettingsActionButtonWrapper>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput style={{gap: 8}}>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Item Customization</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <CustomizationContainer>
                                    <FastField 
                                        name="email.customer.order_created.dishes.enabled"
                                        render={({ field }: any) =>
                                        <CustomizationItem>
                                            <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Show Items</p>
                                            <Switch
                                                id="customization_dishes_enabled"
                                                checked={
                                                    field.value
                                                }
                                                onChange={(
                                                    e
                                                ) =>
                                                    setFieldValue(
                                                        "email.customer.order_created.dishes.enabled",
                                                        e
                                                            .target
                                                            .checked
                                                    )
                                                }
                                            />
                                        </CustomizationItem>
                                        }
                                    />
                                    
                                    <FastField 
                                        name="email.customer.order_created.dishes.prices"
                                        render={({ field }: any) =>
                                        <CustomizationItem>
                                            <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Show Item Prices</p>
                                            <Switch
                                                id="email.customer.order_created.dishes.prices"
                                                checked={
                                                    field.value
                                                }
                                                onChange={(
                                                    e
                                                ) =>
                                                    setFieldValue(
                                                        "email.customer.order_created.dishes.prices",
                                                        e
                                                            .target
                                                            .checked
                                                    )
                                                }
                                            />
                                        </CustomizationItem>
                                        }
                                    />

                                    <FastField 
                                        name="email.customer.order_created.dishes.option_prices"
                                        render={({ field }: any) =>
                                        <CustomizationItem>
                                            <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Show Item Option-set Prices</p>
                                            <Switch
                                                id="email.customer.order_created.dishes.option_prices"
                                                checked={
                                                    field.value
                                                }
                                                onChange={(
                                                    e
                                                ) =>
                                                    setFieldValue(
                                                        "email.customer.order_created.dishes.option_prices",
                                                        e
                                                            .target
                                                            .checked
                                                    )
                                                }
                                            />
                                        </CustomizationItem>
                                        }
                                    />
                                    <FastField 
                                        name="email.customer.order_created.dishes.combo_choice_names"
                                        render={({ field }: any) =>
                                        <CustomizationItem>
                                            <p style={{flex: 1, fontSize: 14, fontWeight: 500}}>Show Combo Item Choice Headings</p>
                                            <Switch
                                                id="email.customer.order_created.dishes.combo_choice_names"
                                                checked={
                                                    field.value
                                                }
                                                onChange={(
                                                    e
                                                ) =>
                                                    setFieldValue(
                                                        "email.customer.order_created.dishes.combo_choice_names",
                                                        e
                                                            .target
                                                            .checked
                                                    )
                                                }
                                            />
                                        </CustomizationItem>
                                        }
                                    />
                                </CustomizationContainer>
                                <SettingsActionButtonWrapper variant="white" position='relative'  nopadding>
                                    <button> Reset</button>
                                </SettingsActionButtonWrapper>
                            </SettingsFormGroupInput>    

                        <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Sound</SettingsFormLabelLeftContainer>
                                <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer>
                            <FastField
                                name="email.customer.order_created.dishes.sort_type"
                                render={({ field }: any) => (
                                    <SelectAdv
                                        type="single"
                                        options={[
                                            {
                                                value: SortType.ByCart.toString(),
                                                label: "Sort By Cart",
                                            },
                                            {
                                                value: SortType.ByCategory.toString(),
                                                label: "Sort By Category",
                                            },
                                        ]}
                                        value={
                                            field.value ||
                                            SortType.ByCart.toString()
                                        }
                                        onChange={(
                                            option: string
                                        ) =>
                                            setFieldValue(
                                                "email.customer.order_created.dishes.sort_type",
                                                option
                                            )
                                        }
                                    />
                                )}
                            />
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput>
                            <SettingsSwitchContainer style={{gap: 16, paddingTop: 8, paddingBottom: 8}}>
                                <SettingsFormLabelLeftContainer style={{fontWeight: 500}}>
                                    Show Order Totals
                                </SettingsFormLabelLeftContainer>
                                <FastField
                                    name="email.customer.order_created.totals"
                                    render={({ field }: any) => (
                                    
                                            <Switch
                                                id="customization_receipt_total"
                                                checked={
                                                    field.value
                                                }
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        "email.customer.order_created.totals",
                                                        e.target
                                                            .checked
                                                    )
                                                }
                                            />
                                    )}
                                />
                            </SettingsSwitchContainer>        
                            </>}
                        </SettingsFormGroup>
                        <SettingsActionButtonContainer>
                            <SettingsActionButtonWrapper>
                                <button type="button" 
                                    className="default btn-cancel" 
                                    onClick={ () => store.setActiveMobileMenu(
                                        store.activeMobileMenu.referrer.pop()!,
                                        Array.from(new Set(store.activeMobileMenu.referrer))
                                    )}>
                                        Cancel 
                                </button>
                                <button type="submit" disabled={isSubmitting}>
                                    {isSubmitting && <RotateLoader size={2} color="white" />}
                                    {!isSubmitting && "Save"}
                                </button>
                            </SettingsActionButtonWrapper>
                        </SettingsActionButtonContainer>
                        </>
                        )}}
            </RestaurantForm>
        </div>
    )
}