import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { CommonText } from '@lib/components';
import { format } from 'date-fns'
import { MobileCustomers } from '../mobileCustomers';

export const CustomersV2 = () => {
    return useObserver(() => {
        const { store } = React.useContext(MobXProviderContext);
        const { mobileSettingsOpen } = store;
        useEffect(() => {
            handleGetCustomerData()
        }, [])

        const handleGetCustomerData = async () => {  
            const data = {
                "page": 1,
                "limit": 10,
                "sort": {
                    "created": -1
                },
                "query": {
                    "restaurants": store.restaurant!._id
                }
            }
            const response = await store.api.customers_find(data);
            if (response.outcome)
                throw new Error(response.message);
            store.updateCustomers({
                items: response.items,
                count: response.count,
            });
        }

        const CustomerWrapper = styled.div`
            .customer-mobile {
                display: none;
            }
            @media (max-width: 767px) {
                .customer-mobile {
                    display: block;
                }
                .customer-desktop {
                    display: none;
                }
            }
            `;

        
        return (
            <>
                {store.organisation && store.organisation.dashboard == 'v2' &&
                    <CustomerWrapper>
					<div className="customer-desktop">
                        <MobileCustomers />
					</div>
					<div className="customer-mobile">
						<MobileCustomers />
					</div>
				</CustomerWrapper>
                }
            </>
        );
    })

};
