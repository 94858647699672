import React, { useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { MinimalViewCard } from './cards/minimalViewCard/minimalViewCard';
import { WithActionBtnsCard } from './cards/withActionBtnsCard/withActionBtnsCard';
import styled from 'styled-components';
import { CommonText, RotateLoader } from '@lib/components';
import { Item } from '../../../notifications/list.components';
import { UI } from '../../../../../../core/ui';
import { displayId, displayNumberOfPeople, displayPhone } from '../bookingdisplayData';
import { BookingDetailModal } from './modals/bookingDetail/bookingDetailModal';
import { cloneDeep } from 'lodash';

export const BookingBody = observer(() => {
    const [openModal, setOpenModal] = useState(false);
    const { store } = React.useContext(MobXProviderContext);
    const bookingData = store.bookings.items;
    
    const r = store.restaurant!;
    const rid = r._id;

    const UnconfirmRemindWrapper = styled.div`
        background: #fefefe;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        border-top: 1px solid #dfdfdf;
        padding: 8px 16px;
        z-index: 1;
        gap: 8px;
        display: none;
        @media (max-width: 767px) {
             display: flex;
            justify-content: center;
            align-items: center;
        }
    `;
    const BookingListWrapper = styled.div`
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 900px;
        width: 100%;
        
    `;

    const handleItemClick = (item: T.Schema.Booking.BookingSchema) => {
        store.router.push(`/restaurant/${rid}/bookings?_id=${displayId(item)}`);
        store.notifications.mark_read_object("booking", displayId(item));
        store.updateBookingControl({ openDetailModal: true })
        store.setBooking(cloneDeep(item));
    };
    const handleClose = () => {
        store.updateBookingControl({ openDetailModal: true })
        store.router.push(`/restaurant/${rid}/bookings`);
    }
    return (
        <>
            <BookingListWrapper>
                {!store.bookingSettings?.displaySetting || store.bookingSettings?.displaySetting === 'minimal_card' ? bookingData.map((item: T.Schema.Booking.BookingSchema) => (
                    <MinimalViewCard
                        key={displayId(item)}
                        title={`${item.number} - ${item.customer.name}`}
                        numberOfPeople={displayNumberOfPeople(item)}
                        bookingTime={item.config.date}
                        timeStamp={item.created}
                        onClick={() => handleItemClick(item)}
                        orderStatus={item.status}
                    />)) : bookingData.map((item: T.Schema.Booking.BookingSchema) => (
                        <WithActionBtnsCard key={displayId(item)}
                            title={`${item.number} - ${item.customer.name}`}
                            numberOfPeople={displayNumberOfPeople(item)}
                            bookingTime={item.config.date}
                            timeStamp={item.created}
                            onClick={() => handleItemClick(item)}
                            phone={displayPhone(item)}
                            email={item.customer?.email}
                            orderStatus={item.status}
                            itemId={item._id}
                        />)) 
                }
            </BookingListWrapper>
            {!store.bookingControl.openDetailModal && <UnconfirmRemindWrapper>
                <img src={'/images/integrations/UnconfirmBooking.svg'} />
                <CommonText size="semiMedium" weight={500} color='#4B4F5E' >
                    {store.unconfirmBookingCount} Unconfirmed Bookings
                </CommonText>
            </UnconfirmRemindWrapper>}

            <BookingDetailModal isOpen={store.bookingControl.openDetailModal} handleClose={handleClose} />
        </>
    );
});
