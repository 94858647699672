import * as React from "react";
import { CoreUtils, logger } from "@lib/common";
import { UI } from "../../../../../core/ui";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { Untrusive } from "@lib/common";
import { ItemListContent, ItemListItem, ItemListWrapper } from "@lib/components";
import { Tag } from "@lib/components";
import { Button } from "@lib/components";
import { ChargebeeSubscriptionPlans, CWSubscriptionPlans } from "@lib/common";
import styled from "styled-components";
import { IconSettings, IconStore } from "../../../../components/v2/icons";
import { SettingsFormInputContainer } from "../../../restaurant/views/settings-v2";

const ActionButton =styled.div`
	display: flex;
	padding: 8px 16px;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	background: #F1F4F7;
	color: #4B4F5E;
	text-align: center;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #f7f8fa;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: contain;
  margin-right: 16px;
`;

const Info = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.h3`
  font-size: 1.2em;
  margin: 0;
  color: #333;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  color: #4B4F5E;
text-align: right;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16px;
`;

const StatusIcon = styled.span<{status: any}>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ status }) => (status === 'active' ? '#009100' : '#dc3545')};
`;

const StatusText = styled.span<{status: any}>`
  font-size: 0.9em;
  color: ${({ status }) => (status === 'active' ? '#009100' : '#dc3545')};
`;

const IconWrapper = styled.div`
	display: flex;
	width: 16px;
	height: 16px;
	padding: 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 99px;
	background: #D9D9D9;
`;
const RestaurantListWrapper = styled.div`
	.mobile-view {
    display: none;
  }
  @media (max-width: 767px) {
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: flex;
	  padding: none;
    }
  }
`
const TotalOrderIcon = () => (
	<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
	<	path d="M4.43077 3.01537L3.90769 2.49229L4.49231 1.90768H2.95385V1.16922H4.49231L3.90769 0.5846L4.43077 0.0615234L5.90769 1.53845L4.43077 3.01537ZM2.21321 7.93845C2.00953 7.93845 1.8359 7.86593 1.69231 7.72089C1.54872 7.57584 1.47692 7.40148 1.47692 7.19781C1.47692 6.99413 1.54944 6.8205 1.69448 6.67691C1.83953 6.53332 2.01389 6.46152 2.21756 6.46152C2.42124 6.46152 2.59487 6.53404 2.73846 6.67908C2.88205 6.82413 2.95385 6.99849 2.95385 7.20216C2.95385 7.40584 2.88133 7.57947 2.73629 7.72306C2.59124 7.86665 2.41688 7.93845 2.21321 7.93845ZM6.64398 7.93845C6.4403 7.93845 6.26667 7.86593 6.12308 7.72089C5.97949 7.57584 5.90769 7.40148 5.90769 7.19781C5.90769 6.99413 5.98021 6.8205 6.12525 6.67691C6.2703 6.53332 6.44466 6.46152 6.64833 6.46152C6.85201 6.46152 7.02564 6.53404 7.16923 6.67908C7.31282 6.82413 7.38462 6.99849 7.38462 7.20216C7.38462 7.40584 7.3121 7.57947 7.16706 7.72306C7.02201 7.86665 6.84765 7.93845 6.64398 7.93845ZM0 0.799985V0.0615234H1.3641L2.95385 3.75383H6.04103L7.2 1.04614H8L6.71795 4.04101C6.65641 4.17776 6.56581 4.28716 6.44615 4.36922C6.3265 4.45127 6.19145 4.49229 6.04103 4.49229H2.75897L2.32821 5.23075H7.38462V5.96922H2.33846C2.05128 5.96922 1.8359 5.84443 1.69231 5.59486C1.54872 5.34528 1.54872 5.10084 1.69231 4.86152L2.22564 3.93845L0.882051 0.799985H0Z" fill="#4B4F5E"/>
	</svg>
)

const OrderLeftIcon = () => (
	<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.625 5H4.375V3.875H5.5V3.125H4.375V2H3.625V3.125H2.5V3.875H3.625V5ZM2.5 7.5V6.5H0.75C0.54375 6.5 0.367188 6.42652 0.220313 6.27956C0.0734375 6.13261 0 5.95594 0 5.74958V1.24705C0 1.04068 0.0734375 0.864583 0.220313 0.71875C0.367188 0.572917 0.54375 0.5 0.75 0.5H7.25C7.45625 0.5 7.63281 0.573479 7.77969 0.720437C7.92656 0.867396 8 1.04406 8 1.25042V5.75295C8 5.95932 7.92656 6.13542 7.77969 6.28125C7.63281 6.42708 7.45625 6.5 7.25 6.5H5.5V7.5H2.5ZM0.75 5.75H7.25V1.25H0.75V5.75Z" fill="#4B4F5E"/>
	</svg>
)
interface Props extends WithTranslation {
	restaurantName: any;
 }
interface State { 
	key_word: string;
	delete_modal_active: any
}

const DetailTag = (props: { text: string; tag: string }) => (
	<div className="flex-line centered flex-no-wrap p-tb-1">
		<p className="">{props.text}</p>
		<Tag className="m-l-2">{props.tag}</Tag>
	</div>
);

function ChargebeeDetails({ t, r, reseller_id }: { t: any, r: T.API.DashboardRestaurantsResponseItem, reseller_id: string }) {

	const cb = r.billing.cb;
	if (!cb) {
		return null;
	}

	let plan;
	if (["cloudwaitress", "cloudwaitress-test"].indexOf(reseller_id) !== -1) {
		plan = plan || CWSubscriptionPlans.v4[cb.plan];
		plan = plan || CWSubscriptionPlans.v3[cb.plan];
		plan = plan || CWSubscriptionPlans.v2[cb.plan];
	}
	else {
		const plans = ChargebeeSubscriptionPlans[reseller_id];
		if (plans) {
			plan = plans.find((p) => p.id === cb.plan);
		}
	}

	return (
		<>
			<DetailTag
				text="Total orders"
				tag={t("number", { value: r.counts.services.total })}
			/>

			{(!plan || plan.included_orders === 0) && (
				<>
					<DetailTag
						text="Orders this cycle"
						tag={t("number", { value: cb.outstanding })}
					/>
					<DetailTag
						text="Next billing"
						tag={t("dateFromTimestamp", { value: cb.next_billing_at })}
					/>
				</>
			)}

			{(plan && plan.included_orders !== 0) && (
				<>
					<DetailTag
						text="Orders left"
						tag={t("number", { value: plan.included_orders - cb.outstanding })}
					/>
					<DetailTag
						text="Free orders reset"
						tag={t("dateFromTimestamp", { value: cb.next_billing_at })}
					/>
				</>
			)}
		</>
	);

}

@inject("store") @observer
class DashboardRestaurantsListV2Class extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			key_word: '',
			delete_modal_active: null
		};
	}

	view = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		const { restrictions } = store;

		if (!restrictions._.restaurantView) {
			return;
		}

		const rr = restrictions.restaurant;
		const r = store.restaurants.items[index];
		let path = `/restaurant/${r._id}`;

		if (rr.dashboard) {
			path = `/restaurant/${r._id}`;
		}
		else if (restrictions._.restaurantOrderViews.length > 0 && restrictions.restaurant.orders_board) {
			path = `/restaurant/${r._id}/orders`;
		}
		else if (restrictions._.restaurantOrderViews.length > 0 && restrictions.restaurant.orders_list) {
			path = `/restaurant/${r._id}/orders/list`;
		}
		else if (restrictions._.restaurantOrderViews.length > 0 && restrictions.restaurant.orders_bump) {
			path = `/restaurant/${r._id}/orders/bump`;
		}
		else if (rr.bookings) {
			path = `/restaurant/${r._id}/bookings`;
		}
		else if (rr.customers) {
			path = `/restaurant/${r._id}/customers`;
		}
		else if (rr.menus) {
			path = `/restaurant/${r._id}/menus`;
		}
		else if (restrictions._.restaurantSettingsEnabled) {
			path = `/restaurant/${r._id}/settings`;
		}

		store.router.push(path);
	}

	delete = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		try {

			const p = prompt("Are you sure you want to delete this restaurant. Once deleted, none of it's data can be recovered. Enter 'delete' into the field below to proceed with delation");

			if (p === null) { return; }

			if (p.toLowerCase() !== "delete") {
				UI.notification.error("Enter 'delete' to delete the restaurant");
				return;
			}

			Untrusive.start();

			const r = store.restaurants.items[index];
			const res = await this.injected.store.api.restaurant_delete({ _id: r._id });

			Untrusive.stop();

			if (res.outcome) {
				UI.notification.error(res.message);
				return;
			}

			const restaurants = [...store.restaurants.items];
			restaurants.splice(index, 1);

			this.setState({ delete_modal_active: null });
			store.updateRestaurants({ items: restaurants });

			UI.notification.success("Restaurant deleted");

		}
		catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, try again or contact us");
			Untrusive.stop();
		}
	}

	render() {
		const reseller = this.injected.store.reseller!;
		const { restaurantName } = this.props;
		const { store, t } = this.injected;
		const { restrictions, restaurant } = store;
		const canViewRestaurant = restrictions._.restaurantView;
		return (
			<ItemListWrapper>
				<RestaurantListWrapper>
					<div  className="desktop-view">
					<ItemListContent style={{ display: 'flex', flexDirection: 'column', gap: '16px', boxShadow: 'none'}}>
					{restaurantName.map((r: any, k: any) => {
						const cb = r.billing.cb;
						const website = r.website;
						const store_url = r.domain ? `https://${r.domain}` : `https://${r.subdomain}.${reseller.store_host}`;
						

						return (
							<ItemListItem
								key={k}
								id={`restaurant-list-item-${k}`}
								style={{ cursor: canViewRestaurant ? "cursor" : "inherit", gap: '16px'}}
								onClick={() => this.view(k)}>
								<div style={{ marginLeft: '16px', width: '11%'}}>
									{website.sections.top_nav.images.logo ?
								<img src={CoreUtils.image.uc(website.sections.top_nav.images.logo, { resize: "x150" })!} style={{	 height: 'auto', width: '100%', objectFit: 'contain' }}/>
								:
								<svg xmlns="http://www.w3.org/2000/svg" style={{width: '100%', maxWidth: 74.8}} width="74.8" height="74.8" viewBox="0 0 136 136" fill="none">
										<path d="M135.2 67.9998C135.2 105.113 105.113 135.2 67.9998 135.2C30.8863 135.2 0.799805 105.113 0.799805 67.9998C0.799805 30.8863 30.8863 0.799805 67.9998 0.799805C105.113 0.799805 135.2 30.8863 135.2 67.9998Z" fill="#FB2F2F"/>
										<path d="M57.5149 30.3486C48.5221 33.9128 49.0842 42.1667 49.6462 45.9185C42.3396 45.7309 38.4053 47.2316 35.4077 51.3586C32.7896 54.963 32.5974 60.9256 33.1595 63.7395C30.5366 63.7395 18.9209 68.054 18.9209 82.4984C18.9209 94.0539 28.8504 100.507 34.8456 101.07H46.836C48.0225 103.008 51.9694 106.885 58.2643 106.885C64.5592 106.885 68.5061 103.008 69.6926 101.07H99.6686C104.415 100.82 114.469 97.318 116.717 85.3123C118.966 73.3065 110.16 66.4283 105.476 64.4898C107.912 61.1132 110.16 51.3808 103.416 45.5433C94.5726 37.8897 85.3675 42.9796 81.8703 46.4813C79.8095 42.9171 75.3881 35.4885 74.189 34.288C72.6902 32.7872 67.2607 26.4859 57.5149 30.3486Z" fill="#FFF3F3"/>
										<path fillRule="evenodd" clipRule="evenodd" d="M74.5685 70.466C76.113 68.8411 77.0609 66.6436 77.0609 64.2247C77.0609 59.2207 73.0043 55.1641 68.0002 55.1641C62.9961 55.1641 58.9395 59.2207 58.9395 64.2247C58.9395 66.6436 59.8874 68.8411 61.4319 70.466L60.1233 72.6872C59.5164 72.1215 58.7021 71.7753 57.8069 71.7753C55.9304 71.7753 54.4092 73.2965 54.4092 75.173C54.4092 76.6898 55.403 77.9745 56.7751 78.4113C54.8635 82.3556 57.7221 87.0652 62.2221 87.0652H73.7782C78.3237 87.0652 81.1945 82.2601 79.1659 78.292C80.3708 77.7698 81.2137 76.5699 81.2137 75.173C81.2137 73.2965 79.6925 71.7753 77.8159 71.7753C77.0394 71.7753 76.3238 72.0358 75.7516 72.4741L74.5685 70.466Z" fill="#FB2F2F"/>
										</svg>
								}
								</div>
								<div className="p-r-5 p-t-5 p-b-4" style={{ width: '33%',}}>

									<div className="flex-l-r-center">
										<h4 className="big m-r-4">{r.name}</h4>
									</div>
									
									<div className="flex-line centered flex-wrap child-mr-20 m-t-2">
										{r.subdomain}.{reseller.store_host}
									</div>		
								</div>
								{!!cb && (
											<Tag
												text="white"
												style={{ padding: "4px 8px", borderRadius: "30px" }}
												background={cb.status === "cancelled" ? store.theme.s.colors.error : store.theme.s.colors.success}>
												{cb.status === "cancelled" ? "Cancelled" : "Active"}
											</Tag>
										)}
								{canViewRestaurant && <ActionButton onClick={() => this.view(k)}><IconSettings /> Manage</ActionButton>}
								<ActionButton
								 style={{ marginRight: '16px'}} 
								 onClick={(e) => {
										e.stopPropagation();
										window.open(store_url);
									}}> 
									<IconStore /> View Store
								</ActionButton>
							</ItemListItem>
						);
					})}
				</ItemListContent>
				</div>
				<div className="mobile-view">
				
				<ListContainer style={{width: '100%'}}>
				
					{restaurantName.map((r: any, k: any) => {
						const cb = r.billing.cb || 'v4-free';
						const website = r.website;
						const store_url = r.domain ? `https://${r.domain}` : `https://${r.subdomain}.${reseller.store_host}`;
						let plan;
						
						if (["cloudwaitress", "cloudwaitress-test"].indexOf(reseller._id) !== -1) {
							plan = plan || CWSubscriptionPlans.v4[cb.plan];
							plan = plan || CWSubscriptionPlans.v3[cb.plan];
							plan = plan || CWSubscriptionPlans.v2[cb.plan];
						}
						else {
							const plans = ChargebeeSubscriptionPlans[reseller._id];
							if (plans) {
								plan = plans.find((p) => p.id === cb.plan);
							}
						}
						return(
						<ListItem key={k} onClick={() => this.view(k)}>
							
							<div style={{ width: '20%', marginRight: '16px'}}>
							{website.sections.top_nav.images.logo ?
								<Logo src={CoreUtils.image.uc(website.sections.top_nav.images.logo, { resize: "x150" })!} style={{	 height: 'auto', width: '100%', objectFit: 'contain' }}/>
								:
								<svg style={{width: '100%', maxWidth: 94.8}} xmlns="http://www.w3.org/2000/svg" width="94.8" height="94.8" viewBox="0 0 136 136" fill="none">
										<path d="M135.2 67.9998C135.2 105.113 105.113 135.2 67.9998 135.2C30.8863 135.2 0.799805 105.113 0.799805 67.9998C0.799805 30.8863 30.8863 0.799805 67.9998 0.799805C105.113 0.799805 135.2 30.8863 135.2 67.9998Z" fill="#FB2F2F"/>
										<path d="M57.5149 30.3486C48.5221 33.9128 49.0842 42.1667 49.6462 45.9185C42.3396 45.7309 38.4053 47.2316 35.4077 51.3586C32.7896 54.963 32.5974 60.9256 33.1595 63.7395C30.5366 63.7395 18.9209 68.054 18.9209 82.4984C18.9209 94.0539 28.8504 100.507 34.8456 101.07H46.836C48.0225 103.008 51.9694 106.885 58.2643 106.885C64.5592 106.885 68.5061 103.008 69.6926 101.07H99.6686C104.415 100.82 114.469 97.318 116.717 85.3123C118.966 73.3065 110.16 66.4283 105.476 64.4898C107.912 61.1132 110.16 51.3808 103.416 45.5433C94.5726 37.8897 85.3675 42.9796 81.8703 46.4813C79.8095 42.9171 75.3881 35.4885 74.189 34.288C72.6902 32.7872 67.2607 26.4859 57.5149 30.3486Z" fill="#FFF3F3"/>
										<path fillRule="evenodd" clipRule="evenodd" d="M74.5685 70.466C76.113 68.8411 77.0609 66.6436 77.0609 64.2247C77.0609 59.2207 73.0043 55.1641 68.0002 55.1641C62.9961 55.1641 58.9395 59.2207 58.9395 64.2247C58.9395 66.6436 59.8874 68.8411 61.4319 70.466L60.1233 72.6872C59.5164 72.1215 58.7021 71.7753 57.8069 71.7753C55.9304 71.7753 54.4092 73.2965 54.4092 75.173C54.4092 76.6898 55.403 77.9745 56.7751 78.4113C54.8635 82.3556 57.7221 87.0652 62.2221 87.0652H73.7782C78.3237 87.0652 81.1945 82.2601 79.1659 78.292C80.3708 77.7698 81.2137 76.5699 81.2137 75.173C81.2137 73.2965 79.6925 71.7753 77.8159 71.7753C77.0394 71.7753 76.3238 72.0358 75.7516 72.4741L74.5685 70.466Z" fill="#FB2F2F"/>
										</svg>
							}
							</div>
						<Info>
							<Title>{r.name}</Title>
							<StatusContainer>
							<div style={{ display: 'flex', gap: '4px'}}>
								<IconWrapper><TotalOrderIcon /> </IconWrapper>
								Total Orders: {r.counts.services.total}
							</div>
							{plan && 
							<div style={{ display: 'flex', gap: '4px'}}>
								<IconWrapper><OrderLeftIcon /> </IconWrapper>
								Orders left: {plan.included_orders - cb.outstanding}
							</div>}
							</StatusContainer>
							<div style={{ display: 'flex', alignItems: 'center', gap: 8}}>
							<StatusIcon status={cb.status} />
							<StatusText status={cb.status }>{cb.status === 'active' ? 'Active' : 'Canceled'}</StatusText>
							</div>
						</Info>
						</ListItem>
					)})}
				</ListContainer>
				</div>
				</RestaurantListWrapper>
				
			</ItemListWrapper>
		);
	}

}

export const DashboardRestaurantsListV2 = withTranslation()(DashboardRestaurantsListV2Class);
