import React, { useState } from 'react';
import { styled } from '@lib/components';
import { ExportIcon, IconPlus, IconQuestionCircle, RefreshIcon } from '../../../../../../components/v2/icons';
import { InputWithIcon } from './inputWithIcon';
import { MobXProviderContext } from 'mobx-react';

export const ButtonWrapper = styled.div<{ bg?: string }>`
    background: ${({ bg }) => bg ? bg : '#F1F4F7'};
    border-radius: 4px;
    width: 40px;
    height: 40px;
    position: relative;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const CustomerToolHeaderWrapper = styled.div`
    display: flex;
    gap: 8px;
    max-width: 856px;
    width: 100%;
    justify-content: center;
    .desktop-view {
        display: flex;
        gap: 8px;
    }
	.mobile-view {
    display: none;
  }
  @media (max-width: 767px) {
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: flex;
      gap: 8px;
	  padding: none;
    }
  }
`

type CustomerToolHeaderProps = {
    setSearchInfo: (data: string) => void;
    searchInfo: string;
}

export const CustomerToolHeader: React.FC<CustomerToolHeaderProps> = ({ searchInfo, setSearchInfo }) => {
    const { store } = React.useContext(MobXProviderContext);
    return (
        <CustomerToolHeaderWrapper>
            <InputWithIcon searchInfo={searchInfo} setSearchInfo={setSearchInfo} />
            {!searchInfo && <>
                <div className='desktop-view'>
                    <ButtonWrapper bg={'#fefefe'}>
                        <IconPlus onClick={() => { }} />
                    </ButtonWrapper>
                    <ButtonWrapper bg={'#fefefe'}>
                        <RefreshIcon onClick={() => { }}  />
                    </ButtonWrapper>
                    <ButtonWrapper bg={'#fefefe'}>
                        <ExportIcon onClick={() => () => store.updateCustomerControl({ openExportModal: true })}  />
                    </ButtonWrapper>
                    <ButtonWrapper bg={'#fefefe'}>
                        <IconQuestionCircle onClick={() => { }} />
                    </ButtonWrapper>
                </div>
                <div className='mobile-view'>
                    <ButtonWrapper>
                        <RefreshIcon onClick={() => { }} />
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <ExportIcon onClick={() => () => store.updateCustomerControl({ openExportModal: true })} />
                    </ButtonWrapper>
                </div>

            </>}
        </CustomerToolHeaderWrapper>
    );
};
