import React, { useState } from 'react';
import {
  SettingsFormGroup,
  SettingsFormGroupInput,
  SettingsFormLabelContainer,
  SettingsFormLabelLeftContainer,
  SettingsFormLabelRightContainer,
  OpeningHoursContainer,
  SettingsActionButtonContainer,
  SettingsActionButtonWrapper,
} from '..';
import { IconHelp } from '../../../../../components/v2/icons';
import { FieldOpeningHours, RotateLoader } from '@lib/components';
import { RestaurantForm } from '../../../../../../mobx/components/restaurant-form';
import { useStores } from '../../../../../../mobx/useStores';
import { updateRestaurantNotificationOptions } from '../../../../../../core/libs/receipt';
import { UI } from '../../../../../../core/ui';
import { FastField } from 'formik';
import styled from 'styled-components';

export const SettingsFieldOpeningHoursContainer = styled.div`
  width: 100%;
  & div[class^="opening_hours__AddTimeSlotButtonStyle"] {
    margin-bottom: 16px;
    @media (max-width: 767px) {
      & + div {
        &  > div:first-child {
          display: none;
        }
      }
    }
  }

  & div[class^="bottom_menu_edit__ControlsWrapper"] {
    width: 100%!important;
  }

  & select[class^="select__SelectComponent"]  {
    width: 100%!important;
  }
  
`

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]


export const AvailableHoursServices = (props: any) => {
  const [hours, setHours] = useState([]);
  const handleHoursChange = (newHours: any) => {
    setHours(newHours);
  };
  const store = useStores();
  const r = store.restaurant!;
  const { injected, referrer } = props

  let service:  T.Schema.Restaurant.Services.RestaurantServiceTypes = 'pickup';
  if(referrer.includes('deliveries')) {
    service = 'delivery';
  } else if(referrer.includes('dine-ins')) {
    service = 'dine_in';
  } else if(referrer.includes('table-booking')) {
    service = 'table_booking';
  }
	let initialValues = r.settings.services[service];

  return (
    <div style={{paddingBottom: 83}}>
    
    <RestaurantForm<FormValues>
      submit={async (r, values) => {
         {/*@ts-ignore*/}
        r.settings.services[service] = values;
        const update = { $set: { [`settings.services.${service}`]: r.settings.services[service] } };
        return { r, update };
      }}
      //@ts-ignore
      initialValues={initialValues}
      onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
      onError={() => UI.notification.error("An error occurred")}
      onSuccessMessage="Settings Updated"
      width='100%'
    >
      {({ form, error, getFieldError }) => {
        const { isSubmitting, setFieldValue } = form;
        return (
          <>
            <SettingsFormGroup>
              <SettingsFormGroupInput>
                <SettingsFormLabelContainer>
                  <SettingsFormLabelLeftContainer>Custom Service Hours</SettingsFormLabelLeftContainer>
                  <SettingsFormLabelRightContainer>
                    <IconHelp />
                  </SettingsFormLabelRightContainer>
                </SettingsFormLabelContainer>
                <SettingsFieldOpeningHoursContainer>
                  <FastField
                    name="hours"
                    render={({ field, form }: any) => (
                    <FieldOpeningHours
                      isMobile
                      allowNone={true}
                      hours={field.value}
                      onChange={(opening_hours) => form.setFieldValue("hours", opening_hours)}
                    /> 
                  )} />                 
                </SettingsFieldOpeningHoursContainer>
                <OpeningHoursContainer>
                  <p>
                    Set custom weekly hours for this service. This will override your existing location opening hours for this
                    particular service. Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or
                    close before they open
                  </p>
                </OpeningHoursContainer>
              </SettingsFormGroupInput>
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
          </>
        )}}
      </RestaurantForm>
    </div>
  );
};
