import React, { useState } from "react"
import styled from "styled-components"
import { Flex } from "../../customerCard/style"
import { Button, CommonText, FormGroup, LinkTag, RotateLoader } from "@lib/components"
import { statusDisplay } from "../customerDetails"
import { MobXProviderContext } from "mobx-react"

export const IdVerification: React.FC<MobileFilterProps> = ({ isOpen, handleClose }) => {
    const { store } = React.useContext(MobXProviderContext);
    const { images, status } = store.customer?.age_verification || {};
    const [idVerification, setIdVerification] = useState({ error: "", loading: false })
    const r = store.restaurant!;
    const c = store.customer!;

    const update = async (status: "approved" | "rejected") => {
		try {
			setIdVerification({ error: "", loading: true });
			const response = await store.api.customer_age_verification_update({
				_id: c._id,
				status: status,
				rid: r._id,
			});
			if (response.outcome) {
				setIdVerification({ error: response.message, loading: false });
			}
			else {
				store.setCustomer(response.item);
				setIdVerification({ error: "", loading: false });
			}
		}
		catch (e) {
			// logger.captureException(e);
			setIdVerification({
				error: "Something went wrong, try again or contact us",
				loading: false,
			});
		}
	}

    return (
        <Flex fd="column" jc="start" g="16px">
            <CommonText size="medium" weight={600} color="#4B4F5E">
                ID Verification
            </CommonText>
            {!status &&
                <Flex jc="space-between" bg="#FEFEFE" p="16px" fd="column" g='16px'>
                    <Flex jc="space-between" w="100%">
                        <CommonText size="semiMedium" color="#6B7270">Status</CommonText>
                        <Flex w="fit-content" p="0px 8px" g="8px" bg='#F1F8E8' br={'4px'}>
                            {statusDisplay('unverified')}
                        </Flex>
                    </Flex>
                    <CommonText size="semiMedium" color="#6B7270">No ID has been uploaded yet. Once uploaded, you will receive a notification and you can approve or reject it</CommonText>
                </Flex>
            }
            {status === "pending" &&
                <Flex jc="space-between" bg="#FEFEFE" p="16px" fd="column" g='16px'>
                    <Flex jc="space-between" w="100%">
                        <CommonText size="semiMedium" color="#6B7270">Status</CommonText>
                        <Flex w="fit-content" p="0px 8px" g="8px" bg='#F1F8E8' br={'4px'}>
                            {statusDisplay('pending')}
                        </Flex>
                    </Flex>
                    <CommonText size="semiMedium" color="#6B7270">
                        Customer has uploaded their ID. Please verify it's validity and confirm the status of their verification request
                    </CommonText>
                    <Flex jc="space-between" ai="space-between" bg="#FEFEFE" p="16px" fd="row" g='16px' w='100%'>
                        <>
                            <FormGroup>
                                <div className="">
                                    {images?.map((img, i) => (
                                        <p key={i} className="m-t-2">
                                            <LinkTag className="m-r-2" target="_blank" href={`https://s3.amazonaws.com/ordering-platform/${img}`}>View Document {i + 1}
                                            </LinkTag>
                                        </p>
                                    ))}
                                </div>
                            </FormGroup>
                            {idVerification.error && <FormGroup error={idVerification.error} />}
                        </>
                        <Flex>
                            <Button color="primary-inverse" size="sm" className="width128" onClick={() => update("approved")}>
                                Approve
                            </Button>
                            <Button color="primary-inverse" size="sm" className="width128 m-l-2" onClick={() => update("rejected")}>
                                Reject
                            </Button>
                            {idVerification.loading && <RotateLoader size={2} />}
                        </Flex>

                    </Flex>
                </Flex>
            }
            {status === "approved" &&
                <Flex jc="space-between" bg="#FEFEFE" p="16px" fd="column" g='16px'>
                    <Flex jc="space-between" w="100%">
                        <CommonText size="semiMedium" color="#6B7270">Status</CommonText>
                        <Flex w="fit-content" p="0px 8px" g="8px" bg='#F1F8E8' br={'4px'}>
                            {statusDisplay('approved')}
                        </Flex>
                    </Flex>
                    <CommonText size="semiMedium" color="#6B7270">
                        This customer's ID has been successfully approved
                    </CommonText>
                    <Flex jc="space-between" ai="space-between" bg="#FEFEFE" p="16px" fd="row" g='16px' w='100%'>
                        <>
                            <FormGroup>
                                <div className="">
                                    {images?.map((img, i) => (
                                        <p key={i} className="m-t-2">
                                            <LinkTag className="m-r-2" target="_blank" href={`https://s3.amazonaws.com/ordering-platform/${img}`}>View Document {i + 1}
                                            </LinkTag>
                                        </p>
                                    ))}
                                </div>
                            </FormGroup>
                            {idVerification.error && <FormGroup error={idVerification.error} />}
                        </>
                        <Flex>
                            <Button color="primary-inverse" size="sm" className="error-bg" onClick={() => update("rejected")}>
                                Revoke ID Approval
                            </Button>
                            {idVerification.loading && <RotateLoader size={2} />}
                        </Flex>

                    </Flex>
                </Flex>
            }
            {status === "rejected" &&
                <Flex jc="space-between" bg="#FEFEFE" p="16px" fd="column" g='16px'>
                    <Flex jc="space-between" w="100%">
                        <CommonText size="semiMedium" color="#6B7270">Status</CommonText>
                        <Flex w="fit-content" p="0px 8px" g="8px" bg='#F1F8E8' br={'4px'}>
                            {statusDisplay('rejected')}
                        </Flex>
                    </Flex>
                    <CommonText size="semiMedium" color="#6B7270">
                        This customer's ID has been successfully approved
                    </CommonText>

                    <FormGroup>
                        <div className="">
                            {images?.map((img, i) => (
                                <p key={i} className="m-t-2">
                                    <LinkTag className="m-r-2" target="_blank" href={`https://s3.amazonaws.com/ordering-platform/${img}`}>View Document {i + 1}
                                    </LinkTag>
                                </p>
                            ))}
                        </div>
                    </FormGroup>
                    {idVerification.error && <FormGroup error={idVerification.error} />}
                </Flex>
            }

        </Flex>
    )

}