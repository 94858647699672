import * as React from "react";
import { darken } from "polished";
import {
	styled,
	Input,
	CS,
	Tooltip,
	Button,
	InputGroup,
	} from "@lib/components";
import {
	FaChevronRight,
	FaChevronDown,
	FaEdit,
	FaTrashAlt,
	FaCopy,
	FaPlay,
} from "react-icons/fa";
import {
	MdModeEdit,
} from "react-icons/md";
import { Draggable } from "react-beautiful-dnd";
import { IoIosPause } from "react-icons/io";
import { PauseTag } from "../components/PauseTag";
import { TimeRemain } from "../components/TimeRemainTag";
import { ResumeModal } from "../components/ResumeModal";
import { MENU_ITEM_TYPE } from "../constant";
import { PausedItemsCountTag } from "../components/PausedItemsCountTag";
import { isMobile } from 'react-device-detect';

const Wrapper = styled("div")``;

const Item = styled("div")<{ drag?: boolean; isPausing?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: move;
	cursor: ${({ drag }) => (drag ? "grab" : "pointer")};
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	width: 100%;
	color: ${({ theme }) => theme.box.text};
	background: ${({ theme }) => theme.box.background};
	padding: 5px 0 5px 16px;
	min-height: 43px;
	${CS.shadow.light.one};
	transition: 0.3s all;
	&:hover {
		background: ${darken(0.02, "white")};
	}
	border-bottom: ${({ isPausing }) => isPausing ? "1px solid #FB2F2F" : "1px solid transparent"}
`;

export const ItemButton = styled(Button)`
	color: ${({ theme }) => darken(0.6, theme.box.background)};
	&:hover {
		color: ${({ theme }) => theme.box.text};
	}
`;

const SubWrapper = styled("div")`
	display: flex;
	justify-content: flex-end;
	padding-top: 6px;
`;

const SubContent = styled("div")`
	width: calc(100% - 25px);
`;

const ButtonsGroupRight = styled("div")`
	display: flex;
	align-items: center;
`;

interface MenuListItemProps {
	id: string;
	type: string;
	name: React.ReactNode;
	index: number;
	mode: "normal" | "reOrder" | "editPrices";
	onCopy?: (index: number) => void;
	onEdit?: (index: number) => void;
	onPause?: (index: number) => void;
	onRemove?: (index: number) => void;
	onClick?: (index: number) => void;
	onPriceEdit?: (price: number) => void;
	buttons?: React.ReactNode;
	children?: React.ReactNode;
	price?: number;
	currency?: T.Core.Business.BusinessCurrency & {
		step: string;
	};
	isPausing?: boolean;
	pauseDuration?: number;
	pauseStart?: number;
	totalItems?: number;
	totalPausedItems?: number;
	isPauseInfinity?: boolean;
}
interface MenuListItemState {
	active: boolean;
	resumeModalOpen: boolean;
}

const toolTipWidth = 60;

export class MenuListItem extends React.PureComponent<
	MenuListItemProps,
	MenuListItemState
> {
	constructor(props: MenuListItemProps) {
		super(props);
		this.state = { 
			active: false,
			resumeModalOpen: false
		};
		this.toggleActive = this.toggleActive.bind(this);
	}

	toggleActive() {
		this.setState({ active: !this.state.active });
		if (this.props.onClick) {
			this.props.onClick(this.props.index);
		}
	}

	render() {
		const {
			id,
			type,
			name,
			index,
			onCopy,
			onEdit,
			onPause,
			onRemove,
			onPriceEdit,
			children,
			buttons,
			mode,
			price,
			currency,
			isPausing,
			pauseDuration,
			pauseStart,
			totalItems,
			totalPausedItems,
			isPauseInfinity
		} = this.props;

		const { active, resumeModalOpen } = this.state;
		
		const handleCloseResumeModal = () => {
			this.setState({
				resumeModalOpen: false
			});
		}
		const handleOpenResumeModal = () => {
			this.setState({
				resumeModalOpen: true
			})
		}
		return (
			<>
			<ResumeModal isOpen={resumeModalOpen} handleClose={handleCloseResumeModal} menuItemIds={[id]} />
			<Draggable
				draggableId={id}
				index={index}
				type={type}
				isDragDisabled={mode !== "reOrder"}
			>
				{(provided) => (
					<Wrapper
						id={id}
						className="list-item"
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<Item
							onClick={this.toggleActive}
							drag={mode === "reOrder"}
							isPausing={isPausing && type === MENU_ITEM_TYPE.DISH}
						>
							<div className="flex-line centered flex-grow" style={{
								wordBreak: "break-all", 
								maxWidth: '50vw'
							}}>
								{!!children &&
									(active ? (
										<FaChevronDown className="m-r-3" style={{
											minWidth: "30px"
										}} />
									) : (
										<FaChevronRight className="m-r-3" style={{
											minWidth: "30px"
										}} />
									))}
								{name}
							</div>
							{mode === "normal" && (
								<ButtonsGroupRight>
									{
										[MENU_ITEM_TYPE.MENU, MENU_ITEM_TYPE.CATEGORY].includes(type) && isPausing && <PausedItemsCountTag totalItems={totalItems} totalPausedItems={totalPausedItems} />
									}
									<PauseTag isPauseInfinity={isPauseInfinity} pauseMenu={isPausing} type={type} pauseDuration={pauseDuration} pauseStart={pauseStart} />
									{buttons}
									{onPause && isPausing && type === MENU_ITEM_TYPE.DISH && <Tooltip
										text="Resume"
										width={toolTipWidth}
										position="top"
									>
										<ItemButton
											type="button"
											color="white"
											className="no-round no-border width32"
											paddinglr={5}
											onClick={(e) => {
												e.stopPropagation();
												handleOpenResumeModal();
											}}
										>
											<FaPlay />
										</ItemButton>
									</Tooltip>}
									{onPause && !isPausing && type === MENU_ITEM_TYPE.DISH && <Tooltip
										text="Pause"
										width={toolTipWidth}
										position="top"
									>
										<ItemButton
											type="button"
											color="white"
											className="no-round no-border width32"
											paddinglr={5}
											onClick={(e) => {
												e.stopPropagation();
												onPause(index);
											}}
										>
											<IoIosPause />
										</ItemButton>
									</Tooltip>}
									{onEdit && (
										<Tooltip
											text="Edit"
											width={toolTipWidth}
											position="top"
										>
											<ItemButton
												type="button"
												color="white"
												className="no-round no-border width32"
												paddinglr={5}
												onClick={(e) => {
													e.stopPropagation();
													onEdit(index);
												} }
											>
												{isMobile ? <MdModeEdit /> : <FaEdit/>}
											</ItemButton>
										</Tooltip>
									)}
									{onRemove && (
										<Tooltip
											text="Delete"
											width={toolTipWidth}
											position="top"
										>
											<ItemButton
												type="button"
												color="white"
												className="no-round no-border width32"
												paddinglr={5}
												onClick={(e) => {
													e.stopPropagation();
													onRemove(index);
												} }
											>
												<FaTrashAlt />
											</ItemButton>
										</Tooltip>
									)}
									{onCopy && (
										<Tooltip
											text="Copy"
											width={toolTipWidth}
											position="top"
										>
											<ItemButton
												type="button"
												color="white"
												className="no-round no-border width32"
												paddinglr={5}
												onClick={(e) => {
													e.stopPropagation();
													onCopy(index);
												} }
											>
												<FaCopy />
											</ItemButton>
										</Tooltip>
									)}
								</ButtonsGroupRight>
							)}
							{mode === "editPrices" && type === "DISH" && (
								<>
									<InputGroup
										iconHtml={<div className="font-semi-bold">
											{currency!.symbol}
										</div>}
									></InputGroup>
									<Input
										type="number"
										step={currency!.step}
										style={{
											maxWidth: "15rem",
											marginRight: "0.2rem",
										}}
										min={0}
										required={true}
										value={price}
										onChange={(e) => {
											e.stopPropagation();

											if (e.target.validity.valid ||
												e.target.value === "") {
												const newPrice = parseFloat(
													e.target.value
												);

												onPriceEdit!(newPrice || 0);
											}
										} } />
								</>
							)}
						</Item>
						{children && active && (
							<SubWrapper>
								<SubContent>{children}</SubContent>
							</SubWrapper>
						)}
					</Wrapper>
				)}
			</Draggable></>
		);
	}
}
