import React, { useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import styled from 'styled-components';
import { Card } from '../customerCard';
import { Button, CommonText, Sidebar } from '@lib/components';
import { CustomerBodyWrapper, ListContainer } from './style';
import { CustomerDetail } from './customerDetails';

export const CustomerBody = observer(() => {
    const [openModal, setOpenModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>(null);
    const { store } = React.useContext(MobXProviderContext);
    const customers = store.customers;
    const {openCustomerDetail, currentCustomer} = store.customerControl;
    const r = store.restaurant!;
    const rid = r._id;

    const handleItemClick = (item: any) => {
        store.updateCustomerControl({openCustomerDetail: true, currentCustomer: item})
    };
    const handleClose = () => {
        store.updateCustomerControl({openCustomerDetail: false, currentCustomer: null})
    }

    const handleGetCustomerData = async () => {
        const pageNumber = store.customers.page + 1;
        const data = {
            "page": 1,
            "limit": 10 * pageNumber,
            "sort": {
                "created": -1
            },
            "query": {
                "restaurants": store.restaurant!._id
            }
        }
        const response = await store.api.customers_find(data);
        if (response.outcome)
            throw new Error(response.message);
        store.updateCustomers({
            items: response.items,
            count: response.count,
            page: pageNumber
        });
    }

    return (
        <CustomerBodyWrapper>
            <div className='mobile-view'>
                {
                    customers.items?.map((customerInfo: any) => <Card key={Math.random()}  handleOpen={() => handleItemClick(customerInfo)} customerInfo={customerInfo} />)
                }

                {customers.items.length >= (10 * store.customers.page) && <Button overrideColor={'#F1F4F7'} customColor='#FB2F2F' full={true} border={true} onClick={() => handleGetCustomerData()}>
                    Show more</Button>
                }
                <Sidebar isOpen={openCustomerDetail} headerTitle="Customer - Details" onClose={handleClose} isMobile={true} version='v2'>
                    {currentCustomer && <CustomerDetail />}
                </Sidebar>
            </div>
            <div className='desktop-view'>
                {
                    !currentCustomer && customers.items?.map((customerInfo: any) => <Card key={Math.random()} customerInfo={customerInfo} handleOpen={() => handleItemClick(customerInfo)} />)
                }

                {!currentCustomer && customers.items.length >= (10 * store.customers.page) && <Button overrideColor={'#F1F4F7'} customColor='#FB2F2F'  border={true} onClick={() => handleGetCustomerData()}>
                    Show more</Button>
                }
                {currentCustomer && <CustomerDetail />}
            </div>
            
        </CustomerBodyWrapper>
    );
});
