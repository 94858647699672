import React, { useState } from "react"
import { SelectAdv, Switch } from "@lib/components"
import { 
    SettingsFormGroup,
    SettingsSwitchContainer,
    SettingsFormLabelLeftContainer,
    SettingSwitchWithTextContainer,
    SettingsFormGroupInput,
    SettingsFormInputContainer,
    SettingsFormLabelContainer,
    SettingsFormLabelRightContainer,
    SalesTaxesAddButton,
    OpeningHoursContainer,
    OpeningHoursCard,
    OpeningHoursCardWrapper,
    EligibleItem,
    EligibleItemContentWrapper,
    EligibleItemIconWrapper,
    EligibleItemsContainer,
    OptionalText,
    SettingsCustomOption,
    SettingsCustomOptionsContainer,
    OpeningHoursCardScheduleWrapper,
    SettingsActionButtonWrapper,
    MobileModal,
    MobileModalBody,
    MobileModalContent,
    MobileModalFooter,
    MobileModalHeader,
    SettingsActionButtonContainer,
} from "."
import { 
    IconCalendar,
    IconHelp,
    IconPlusRed,
    IconEligibleItemCheck,
    IconArrowForward,
    IconEligibleItemCheckDefault,
    IconGreenEllipse,
    IconRedEllipse,
    IconClose,
    IconCheck,
    IconHour
} from "../../../../components/v2/icons"
import styled from "styled-components"
import { useStores } from "../../../../../mobx/useStores"
import { 
    PromoCard,
    PromoCardContainer,
    PromoCardContent,
    PromoCardStatus,
    PromoCardTag
} from "./promo-codes"
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { FastField } from "formik";
import { RotateLoader } from "@lib/components"
import { ListOrderTimesOptions, ListServicesOptions, ListPaymentsOptions } from "@lib/common";
import { cloneDeep } from "lodash";
import uuid from 'uuid';
import shortid from 'shortid';
import { defaultOpeningHour } from "./location"
const TIMESLOTS = [
    {day: 'Monday', hours: '9:00 - 14:00'},
    {day: 'Tuesday', hours: '24 Hours'},
    {day: 'Wednesday', hours: '24 Hours'},
]

type FormValues = T.Schema.Restaurant.Services.RestaurantServiceFee;
interface Props {
	initialValues: FormValues | null;
	close: () => void;
}

export const SettingsConditionalFees = (props: any) => {
    const store = useStores()
    const {setActiveMobileMenu, setActiveMobileModal } = store
    const { activeMobileMenu, activeMobileModal, saveRestaurant, conditionalFees, injected } = props
    const [selectedFee, setSelectedFee] = useState<T.Schema.Restaurant.Services.RestaurantServiceFee | null>(null)
    const [edit, setEdit] = useState(false)
    const [indexHour, setIndexHour] = useState(-1)
    const [openingHour, setOpeningHour] = useState(defaultOpeningHour)
    const r = store.restaurant!

    		
    const create = (fee: T.Schema.Restaurant.Services.RestaurantServiceFee | null): T.Schema.Restaurant.Services.RestaurantServiceFee => {
		if (fee === null) {
			const _id = shortid.generate();
			return {
				_id: _id,
				name: "",
				fixed_value: 0,
				percent_value: 0,
				match_condition: "any",
				services: [],
				payments: [],
				order_times: [],
				hours: [],
				disabled: false,
				tax_exempt: false,
			};
		}
		return fee;
	}
	const copy = async (index: number | null) => {
		if (index === null) return;
		await saveRestaurant({
			successMsg: "Fee copied",
			process: (r: any) => {
				const item = cloneDeep(r.settings.fees[index]);
				item._id = shortid.generate();
				item.name = `${item.name} - Copy`;
				r.settings.fees.push(item);
				return {
					update: { $set: { "settings.fees": r.settings.fees } },
				};
			},
		}, injected);
	}
	const remove = async (index: number | null) => {
		if (index === null) return;
		await saveRestaurant({
			successMsg: "Fee deleted",
			process: (r: any) => {
				r.settings.fees.splice(index, 1);
				return {
					update: { $set: { "settings.fees": r.settings.fees } },
				};
			}
		}, injected);
	}

	let initialValues = null;
    if (activeMobileMenu == 'add-conditional-fees') {
        initialValues = create(null);
    }
    else {
        if(selectedFee) {
        const item = r.settings.fees.find((p) => p._id === selectedFee!._id);
        initialValues = create(item || null);
        }
    }
    const currency = store.intl.s.currency;
    return (
        <div style={{paddingBottom: 83, width: '100%'}}>
             {activeMobileMenu == 'conditional-fees' && 
               
                <PromoCardContainer>
                    <SettingsFormLabelContainer className="desktop-only">
                        <SettingsFormLabelLeftContainer>Fees</SettingsFormLabelLeftContainer>
                        <SalesTaxesAddButton 
                            variant="red"
                            type='button' 
                            onClick={() =>  setActiveMobileMenu('add-conditional-fees', 'conditional-fees') }>
                            <IconPlusRed /> Add Fee
                        </SalesTaxesAddButton>
                    </SettingsFormLabelContainer>
                    {conditionalFees.map((fee: T.Schema.Restaurant.Services.RestaurantServiceFee , index: number) => (
                        <PromoCard 
                            key={index}
                            onClick={() => {
                                setSelectedFee(fee)
                                setActiveMobileMenu('edit-conditional-fees', 'conditional-fees')
                        }}>
                            <PromoCardContent>
                               {fee.name}
                            </PromoCardContent>

                            <PromoCardStatus variant={fee.disabled ? 'red': ''}>
                                {fee.disabled ?<>
                                    <IconRedEllipse />
                                    In-Active
                                </>:<><IconGreenEllipse />
                                Active</> }
                            </PromoCardStatus>
                        </PromoCard>
                    ))}
                </PromoCardContainer>
            }

            {(activeMobileMenu == 'add-conditional-fees' || activeMobileMenu == 'edit-conditional-fees') && 
                <RestaurantForm<FormValues>
                    submit={async (r, values) => {
                        const feeList = [...(r.settings.fees) || []]
                        const existingItem = feeList.findIndex((p) => p._id === values._id);
                        if (existingItem === -1) {
                            feeList.push(values);
                            r.settings.fees = feeList
                        }
                        else {
                            r.settings.fees[existingItem] = values;
                        }
                        return {
                            r: r,
                            update: { $set: { "settings.fees": r.settings.fees } },
                        };
                    }}
                    validators={{
                        name: (values) => {
                            if (!values.name)
                                return { name: "This field is required" };
                        },
                    }}
                    initialValues={initialValues}
                    onSuccess={close}
                    onSuccessMessage={activeMobileMenu === "edit-conditional-fees" ? "Fee edited" : "Fee created"}
                >
                    {({ form, error, getFieldError }) => {
                        const { isSubmitting, setFieldValue } = form;
                        return (<><SettingsFormGroup>
                    <SettingSwitchWithTextContainer>
                        <SettingsSwitchContainer>
                            <SettingsFormLabelLeftContainer>
                                Disable Conditional Fee
                            </SettingsFormLabelLeftContainer>
                            <FastField
                                name="disabled"
                                render={({ field }: any) => (
                                    <Switch
                                        id="disabled-fee"
                                        checked={field.value}
                                        variant="black"
                                        onChange={(e) => setFieldValue("disabled", e.target.checked)} />
                                )}
                            />
                        </SettingsSwitchContainer>
                        <p>Disable the usage of this promo code without deleting it</p>
                    </SettingSwitchWithTextContainer>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                            <FastField
                                name="name"
                                render={({ field }: any) => (
                                    <input {...field} required />
                            )} />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Fix Fee</SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                            $
                            <FastField
                                name="fixed_value"
                                render={({ field }: any) => (
                                    <input
                                        type="number"
                                        step={currency.step}
                                        min={0} 
                                        required={true}
                                        {...field} />
                                )}
                            />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Percent Fee</SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                            $
                            <FastField
                                name="percent_value"
                                render={({ field }: any) => (
                                    <input
                                    type="number"
                                    step={0.01}
                                    min={0}
                                    max={100}
                                    required={true}
                                    {...field} />
                                        
                                )}
                            />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Fee Match Condition</SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer  style={{paddingLeft: 0, paddingRight: 0, border: 'none'}}>
                        <SettingsCustomOptionsContainer style={{flexDirection: 'row', width: '100%'}}>
                            <FastField
                                name="match_condition"
                                render={({ field }: any) => (
                                [
                                    { value: "any", name: "Any" },
                                    { value: "specific", name: "Specific" },
                                ].map((f, index) => {
                                    return (
                                    <SettingsCustomOption
                                        key={index}
                                        style={{flex: 1}}
                                        onClick={() => {
                                            setFieldValue("match_condition", f.value)
                                        }}
                                        className={field.value === f.value ? 'active': ''}>
                                        {f.name}
                                        {field.value === f.value  && <IconCheck /> }
                                    </SettingsCustomOption>
                                    )
                                })
                            )} />
                        </SettingsCustomOptionsContainer>
                        
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Order Times</SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                            
                            <FastField
                                name="order_times"
                                render={({ field }: any) => (
                                
                                    <SelectAdv
                                            type="multi"
                                            value={field.value}
                                            onChange={(options: string[]) => {
                                                setFieldValue("order_times", options);
                                            }}
                                            options={ListOrderTimesOptions} />
                                    
                                )}
                            />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer>Services <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                        <FastField
                            name="services"
                            render={({ field }: any) => (
                                <SelectAdv
                                        type="multi"
                                        value={field.value}
                                        onChange={(options: string[]) => {
                                            setFieldValue("services", options);
                                        }}
                                        options={ListServicesOptions} />
                            
                            )}
                        />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingsFormGroupInput>
                        <SettingsFormLabelContainer>
                            <SettingsFormLabelLeftContainer style={{flex: '2 0 0'}}>Payment Methods <OptionalText>(optional)</OptionalText></SettingsFormLabelLeftContainer>
                            <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                        </SettingsFormLabelContainer>
                        <SettingsFormInputContainer>
                        <FastField
                            name="payments"
                            render={({ field }: any) => (
                            <SelectAdv
                                    type="multi"
                                    value={field.value}
                                    onChange={(options: string[]) => {
                                        setFieldValue("payments", options);
                                    }}
                                    options={ListPaymentsOptions} />
                            )}
                        />
                        </SettingsFormInputContainer>
                    </SettingsFormGroupInput>
                    <SettingSwitchWithTextContainer>
                        <SettingsSwitchContainer>
                            <SettingsFormLabelLeftContainer>
                            Applicable Hours
                            </SettingsFormLabelLeftContainer>
                            <SalesTaxesAddButton
                                type="button"
                                onClick={() => {
                                    setActiveMobileModal('add-conditional-fees-hours')
                                }}>
                                <IconPlusRed /> Add Time Slot
                            </SalesTaxesAddButton>
                        </SettingsSwitchContainer>
                        <p>Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open</p>
                        <OpeningHoursContainer>
                          {form.values.hours.map((times, index) => {
                                return (
                                <OpeningHoursCard 
                                    key={index}
                                    onClick={() => {
                                        setActiveMobileModal('add-conditional-fees-hours')
                                        setOpeningHour(times)
                                        setEdit(true)
                                        setIndexHour(index)
                                }}>
                                    <OpeningHoursCardWrapper>
                                        {times.day}
                                        <OpeningHoursCardScheduleWrapper style={{flex: 1}}>
                                            <IconHour />
                                        {times.h24 ? '24 Hours' :
                                        `${times.open} - ${times.close}`}
                                        </OpeningHoursCardScheduleWrapper>
                                    </OpeningHoursCardWrapper>
                                </OpeningHoursCard>
                                )
                            })}
                        </OpeningHoursContainer>
                    </SettingSwitchWithTextContainer>
                    <SettingSwitchWithTextContainer>
                        <SettingsSwitchContainer>
                            <SettingsFormLabelLeftContainer>
                            Disable Tax on this Fee
                            </SettingsFormLabelLeftContainer>
                            <FastField
                                name="tax_exempt"
                                render={({ field }: any) => (
                                    <Switch
                                        id="tax_exempt-fee"
                                        checked={field.value}
                                        variant="black"
                                        onChange={(e) => setFieldValue("tax_exempt", e.target.checked)} />
                                )}
                            />
                        </SettingsSwitchContainer>
                        <p>Should this fee be exempt from tax?</p>
                    </SettingSwitchWithTextContainer>
                </SettingsFormGroup>
                <SettingsActionButtonContainer>
                    <SettingsActionButtonWrapper>
                        <button 
                            type="button" 
                            className='btn-cancel'
                            onClick={ () => store.setActiveMobileMenu(
                                store.activeMobileMenu.referrer.pop()!,
                                Array.from(new Set(store.activeMobileMenu.referrer))
                            )}>
                            Cancel
                        </button>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                        </button>
                    </SettingsActionButtonWrapper>
                </SettingsActionButtonContainer>
                {activeMobileModal === 'add-conditional-fees-hours' && <MobileModal>
                <MobileModalContent>
                    <MobileModalHeader>
                        {edit ? 'Edit': 'Add'} Applicable Hours
                        <span onClick={() => {
                            setActiveMobileModal('')
                            setEdit(false)
                        }}>
                            <IconClose />
                        </span>
                    </MobileModalHeader>
                    <MobileModalBody>
                           <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Day</SettingsFormLabelLeftContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer>
                                <select 
                                    value={openingHour.day}
                                    onChange={(e) => {
                                    setOpeningHour({
                                        ...openingHour,
                                        day: e.target.value
                                    })
                                }}>
                                    <option>Monday</option>
                                    <option>Tuesday</option>
                                    <option>Wednesday</option>
                                    <option>Thursday</option>
                                    <option>Friday</option>
                                    <option>Saturday</option>
                                    <option>Sunday</option>
                                </select>
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput> 
                        <SettingsSwitchContainer>
                            <SettingsFormLabelLeftContainer>
                                Open 24 Hours
                            </SettingsFormLabelLeftContainer>
                            <Switch
                                id="h24"
                                checked={openingHour.h24}
                                onChange={(e) => {
                                    setOpeningHour({
                                        ...openingHour,
                                        h24: e.target.checked
                                    })
                                }}
                                variant="black"
                                />
                        </SettingsSwitchContainer>
                        <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Open</SettingsFormLabelLeftContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer className={openingHour.h24 ? 'disabled': ''}>
                                <input 
                                    value={openingHour.open}
                                    onChange={(e) => {
                                        setOpeningHour({
                                            ...openingHour,
                                            open: e.target.value
                                        })
                                    }}
                                    disabled={openingHour.h24}
                                    placeholder='9:00' />
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput>
                        <SettingsFormGroupInput>
                            <SettingsFormLabelContainer>
                                <SettingsFormLabelLeftContainer>Close</SettingsFormLabelLeftContainer>
                            </SettingsFormLabelContainer>
                            <SettingsFormInputContainer className={openingHour.h24 ? 'disabled': ''}>
                                <input
                                    value={openingHour.close}
                                    onChange={(e) => {
                                        setOpeningHour({
                                            ...openingHour,
                                            close: e.target.value
                                        })
                                    }} 
                                    disabled={openingHour.h24}
                                    placeholder='21:00' />
                            </SettingsFormInputContainer>
                        </SettingsFormGroupInput>
                    </MobileModalBody>
                    <MobileModalFooter>
                        {edit ?
                        <>
                        <SettingsActionButtonWrapper position='relative' noborder nopadding>
                            <button 
                                type='button'
                                onClick={() => {
                                    setActiveMobileModal('')
                                    setEdit(false)
                                    const copyHours = form.values.hours
                                    //@ts-ignore
                                    copyHours[indexHour] = openingHour
                                    setFieldValue('hours', [...copyHours])
                                
                                }}
                                > Save</button>
                        </SettingsActionButtonWrapper>
                        </>
                        :<SettingsActionButtonWrapper 
                            position='relative' noborder nopadding>
                            <button type="button" onClick={() => {
                                setActiveMobileModal('')
                                setEdit(false)
                                setFieldValue('hours', form.values.hours.concat(openingHour as T.Core.Business.BusinessHoursSlot))
                            }}> Add</button>
                        </SettingsActionButtonWrapper> }
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal>}
                </>
                
                )}}
            </RestaurantForm> }

            {(activeMobileMenu == 'conditional-fees') && <SettingsActionButtonWrapper className="mobile-only">
               <button onClick={() => setActiveMobileMenu('add-conditional-fees', 'conditional-fees')}> { activeMobileMenu === 'edit-conditional-fees' ? 'Update': 'Add' }</button>
           </SettingsActionButtonWrapper>}

           {activeMobileModal === 'edit-conditional-fees' && <MobileModal>
                <MobileModalContent>
                    <MobileModalHeader>
                        <div></div>
                        <span onClick={() => {
                            setActiveMobileModal('')
                        }}>
                            <IconClose />
                        </span>
                    </MobileModalHeader>
                    <MobileModalFooter>
                        <SettingsActionButtonWrapper 
                            onClick={() => { 
                                setActiveMobileModal('')
                                setActiveMobileMenu('conditional-fees')
                                copy(conditionalFees.findIndex((c: any) => selectedFee?._id === c._id))
                            }}
                            variant="white"
                            position='relative'
                            noborder nopadding>
                            <button> Duplicate this Fee</button>
                        </SettingsActionButtonWrapper>
                        <SettingsActionButtonWrapper 
                            onClick={() => { 
                                setActiveMobileModal('')
                                setActiveMobileMenu('conditional-fees')
                                remove(conditionalFees.findIndex((c: any) => selectedFee?._id === c._id))
                            }}
                            variant="default"
                            position='relative'
                            noborder nopadding>
                            <button> Delete</button>
                        </SettingsActionButtonWrapper>
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal> }
        </div>
    )
}