import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { CommonText } from '@lib/components';
import { format } from 'date-fns'
import { MobileBookings } from '../mobileBookings';
import { DesktopBookings } from './desktopBookings';

export const BookingsV2 = () => {
    return useObserver(() => {
        const { store } = React.useContext(MobXProviderContext);
        const { mobileSettingsOpen } = store;

        useEffect(() => {
            handleGetSettingData();
            handleGetBookingData();
            handleGetUnconfirmCount();
        }, [])

        const handleGetSettingData = async () => {
            const settingResponse = await store.api.get_bookings_setting(store.restaurant!._id);
            if (settingResponse.outcome !== 0)
                throw new Error(settingResponse.message);
            store.setBookingSetting(settingResponse.data);
        }

        const handleGetBookingData = async () => {
            const response = await store.api.get_bookings(store.restaurant!._id);

            if (response.outcome !== 0)
                throw new Error(response.message);
            store.updateBookings({
                items: response.data,
            });
            store.updateFilteredBookings({
                filterType: {
                    sortOrder: 'asc', sortBy: 'status', filterStatus: 'all'
                }
            })
        }
     
        const handleGetUnconfirmCount = async () => {
            const response = await store.api.get_count_unconfirmed_booking(store.restaurant!._id);
            store.setUnconfirmBookingCount(response.data)
        }

        const BookingWrapper = styled.div`
            .booking-mobile {
                display: none;
            }
            @media (max-width: 767px) {
                .booking-mobile {
                    display: block;
                }
                .booking-desktop {
                    display: none;
                }
            }
        `;

        
        return (
            <>
                {store.organisation && store.organisation.dashboard == 'v2' &&
                    <BookingWrapper>
                        <div className="booking-desktop">
                            <DesktopBookings />
                        </div>
                        <div className="booking-mobile">
                            <MobileBookings />
                        </div>
                    </BookingWrapper>}
            </>
        );
    })

};
