import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { CommonText } from '@lib/components';
import { CustomerBody } from './customerBody';
import { MobileControl } from '../../../components/mobileControls';
import { CustomerFilter } from './customerBody/customerFilter';
import { CSVExport } from '../../bookings/mobileBookings/modals/csvExport';
import { format } from 'date-fns'
import { CustomerToolHeader } from './customerToolHeader';
import { SettingsHeader, SettingsHeaderTextBreadcrumbs, SettingsHeaderTextContainer, SettingsHeaderTextTitle, SettingsHeaderTextWrapper } from '../../settings-v2';

export const HEADER_TOP_HEIGHT = 57;

export const HEADER_HEIGHT = 53;

const MobileCustomersWrapper = styled.div`
    /* padding: 16px; */
`;

const CustomerHeader = styled.div`
    position: fixed;
    width: 100%; 
    background: #FEFEFE;
    left: 0;
    top: ${HEADER_TOP_HEIGHT}px;
    padding: 8px 16px;
    z-index: 1;
    @media (min-width: 768px) {
        background: transparent;
        position: static;
        display: flex;
        justify-content: center;
    }
`;

const CustomerBodyWrapper = styled.div<{customerDetailOpen: boolean}>`
    flex-direction: column;
    display: flex;
    gap: 16px;
    position: relative;
    top: ${HEADER_TOP_HEIGHT}px;
    height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 1rem;
    @media (min-width: 768px) {
        top: 0px;
        height: ${({customerDetailOpen}) => customerDetailOpen ? css`calc(100vh - 120px)`: css`calc(100vh - 170px)`}
    }
`;

const EmptySearch = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
    height: 80vh;
`;

export const MobileCustomers
    = () => {
        return useObserver(() => {
            const width = window.innerWidth;
            const { store } = React.useContext(MobXProviderContext);
            const { mobileSettingsOpen } = store;
            const { openExportModal, openFilterModal, openCustomerDetail, currentCustomer } = store.customerControl;
            const [searchInfo, setSearchInfo] = useState('');

            const exportCustomData = async (startDate: Date, endDate: Date) => {
                // const r = store.restaurant!;
                // const rid = r._id;
                // const response = await store.api.downloadCSVBooking(rid, startDate, endDate);
                // // Create a Blob with the CSV data and type
                // const blob = new Blob([response], { type: 'text/csv' });

                // // Create a URL for the Blob
                // const url = URL.createObjectURL(blob);

                // // Create an anchor tag for downloading
                // const a = document.createElement('a');

                // // Set the URL and download attribute of the anchor tag
                // a.href = url;
                // a.download = `booking(${format(startDate, '')} - ${format(endDate, '')}).csv`;

                // // Trigger the download by clicking the anchor tag
                // a.click();
            };

            return (
                <MobileCustomersWrapper>
                    <SettingsHeader>
                        <SettingsHeaderTextContainer>
                            <SettingsHeaderTextWrapper>
                                <SettingsHeaderTextTitle>{true ? 'Customer' : ``}</SettingsHeaderTextTitle>
                                <SettingsHeaderTextBreadcrumbs style={{ flexDirection: 'row', justifyContent: 'start', gap: 4 }}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => { store.router.push("/") }}>Home</div> /
                                    <div style={{ cursor: 'pointer' }} onClick={() => { store.router.push(`/restaurant/${store.restaurant!._id}`) }}>Dashboard </div> /
                                    {currentCustomer && <div style={{ cursor: 'pointer' }} onClick={() => {
                                        store.router.push(`/restaurant/${store.restaurant!._id}/customers`)
                                        store.updateCustomerControl({ currentCustomer: null })
                                    }}>Customers</div>}
                                </SettingsHeaderTextBreadcrumbs>
                            </SettingsHeaderTextWrapper>
                        </SettingsHeaderTextContainer>

                    </SettingsHeader>
                    {
                        !currentCustomer && !mobileSettingsOpen && (
                            <CustomerHeader>
                                <CustomerToolHeader setSearchInfo={setSearchInfo} searchInfo={searchInfo} />
                            </CustomerHeader>
                        )
                    }
                    <CustomerBodyWrapper customerDetailOpen={!!currentCustomer}>
                        {!searchInfo ? <CustomerBody />
                            : <EmptySearch>
                                <CommonText size="medium" weight={600} color="#4B4F5E">Your search has returned no result</CommonText>
                            </EmptySearch>}

                    </CustomerBodyWrapper>
                    <CSVExport isOpen={openExportModal} handleClose={() => store.updateCustomerControl({ openExportModal: false })} handleExport={exportCustomData} />
                    <CustomerFilter isOpen={openFilterModal} handleClose={() => store.updateCustomerControl({ openFilterModal: false })} />
                </MobileCustomersWrapper>
            );
        })

    };
