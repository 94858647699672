import React, { useEffect, useState } from 'react';
import { BookingSearch } from './bookingSearch';
import styled from 'styled-components';
import { BookingBody } from './bookingBody';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { CSVExport } from './modals/csvExport';
import { MobileFilter } from './modals/filter';
import { CommonText } from '@lib/components';
import { format } from 'date-fns'
import { BookingBodyWrapper, BookingHeader, EmptySearch, MobileBookingsWrapper } from '../bookings-v2/bookingV2Component';



export const MobileBookings = () => {
    return useObserver(() => {
        const { store } = React.useContext(MobXProviderContext);
        const { mobileSettingsOpen } = store;
        const [searchInfo, setSearchInfo] = useState('');

        const exportCustomData = async (startDate: Date, endDate: Date) => {
            const r = store.restaurant!;
            const rid = r._id;
            const response = await store.api.downloadCSVBooking(rid, startDate, endDate);
            // Create a Blob with the CSV data and type
            const blob = new Blob([response], { type: 'text/csv' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create an anchor tag for downloading
            const a = document.createElement('a');

            // Set the URL and download attribute of the anchor tag
            a.href = url;
            a.download = `booking(${format(startDate, '')} - ${format(endDate, '')}).csv`;

            // Trigger the download by clicking the anchor tag
            a.click();
        };
        return (
            <MobileBookingsWrapper>
                {
                    !mobileSettingsOpen && (
                        <BookingHeader>
                            <BookingSearch  setSearchInfo={setSearchInfo} searchInfo={searchInfo}  />
                        </BookingHeader>
                    )
                }
                <BookingBodyWrapper style={{}}>
                    {searchInfo && store.bookings.items.length === 0 ?
                        <EmptySearch>
                            <CommonText size="medium" weight={600} color="#4B4F5E">Your search has returned no result</CommonText>
                        </EmptySearch>
                        : <BookingBody />
                    }
                </BookingBodyWrapper>
                <CSVExport isOpen={store.bookingControl.openExportModal} handleClose={() => store.updateBookingControl({ openExportModal: false })} handleExport={exportCustomData} />
                <MobileFilter isOpen={store.bookingControl.openFilterModal} handleClose={() => store.updateBookingControl({ openFilterModal: false })} />
            </MobileBookingsWrapper>
        );
    })

};
